import React, { useState } from "react";
import { Modal } from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import TextField from "@material-ui/core/TextField";
import axios from "axios";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { isTablet } from 'react-device-detect';
import CookieService from "../../services/CookieService";

import CircularProgress from "@material-ui/core/CircularProgress";
import CreateReturn from "./CreateReturn";





const useStyles = makeStyles((theme) => ({
    modal: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    paper: {
        width: "90%",
        height: "30%",
        backgroundColor: theme.palette.background.paper,
        borderRadius: 5,
    },
    paperPositive: {
        width: "90%",
        height: "90%",
        backgroundColor: theme.palette.background.paper,
        borderRadius: 5,
    },
    root: {
        display: "flex",
    },
    clickArea: {
        display: "flex",
    },
    content: {
        flex: 1,
    },
    cover: {
        maxHeight: 100,
        height: "80%",
        width: "30%",
        marginRight: "3%",
    },
    description: {
        minHeight: 40,
        paddingBottom: "5px",
    },
}));


function NewReturnModal({ openModal, closeModal, closeMod, model, comment, setComment, cookie, fetchData }) {
    const classes = useStyles();
    const [loading, setLoading] = useState(false);
    const [noResult, setNoResult] = useState(true);
    const [arrAssignments, setArrAssignments] = useState([]);

    const [selectedPositions, setSelectedPositions] = useState([]);
    const [loadingPositions, setLoadingPositions] = useState(true);


    const [currentAss, setCurrentAss] = useState({});
    const [selectionClicked, setSelectionClicked] = useState(false);


    const theme = useTheme();
    const cardStyles = () => {
        return {
            margin: theme.spacing(2),
            paddingLeft: 20,
        };
    };

    const onInputSearch = (value) => {
        setLoading(true);
        fetchAssignments(value);
    }

    const setAssignment = (value) => {
        setCurrentAss(value);
        // closeModal();
        setSelectionClicked(true);
        fetchPositions(value.AUFTRAGSNR);

    }

    const fetchPositions = (AUFTRAGSNR) => {
        const bAuth = "Bearer " + CookieService.get("access_token");
        const url = "/return/get/positions/" + AUFTRAGSNR;
        axios
            .get(url, {
                headers: { Authorization: bAuth },
            })
            .then((res) => {
                if (res.data.code !== 200) {
                    setSelectionClicked(false);
                } else {
                    setSelectedPositions(res.data.positions);
                    setLoadingPositions(false);
                }
            })
            .catch((err) => {
                setSelectionClicked(false);
                console.log(err);
            });
    }


    const fetchAssignments = (search) => {
        let count = 3;

        if (isTablet) {
            count = 6;
        }
        const bAuth = "Bearer " + CookieService.get("access_token");
        const url = "/return/search/assignment/" + search + "/" + count;
        axios
            .get(url, {
                headers: { Authorization: bAuth },
            })
            .then((res) => {
                if (res.data.code !== 200) {
                    setNoResult(true);
                    setLoading(false);
                } else {
                    setArrAssignments(res.data.assignments);
                    setNoResult(false);
                    setLoading(false);
                }
            })
            .catch((err) => {

                setNoResult(true);
                setLoading(false);
                console.log(err);
            });
    }

    return (
        <>
            <CreateReturn
                open={selectionClicked}
                setOpen={setSelectionClicked}
                assignment={currentAss}
                loading={loadingPositions}
                selectedPositions={selectedPositions}
                updateSelection={setSelectedPositions}
                closeModal={closeModal()}
                fetchNew={fetchData}
            />

            <Modal
                open={openModal}
                onClose={closeModal()}
                aria-labelledby="Modal für neuen Auftrag"
                aria-describedby="Suche und erzeuge eine neue Retoure"
                className={classes.modal}
            >
                <div className={classes.paperPositive} style={{ paddingTop: 5 }}>
                    <div style={{ textAlign: "right", marginRight: 5 }}>
                        <CloseIcon
                            className={classes.icon}
                            onClick={closeModal()}
                            style={{ paddingLeft: "45px" }}
                        />
                    </div>

                    <div style={{ textAlign: "center", fontSize: 14, paddingLeft: 10, paddingRight: 10 }}>
                        Bitte geben Sie Auftagsnr. / Rechnungsnr./ Referenz ein.  <br /><br />
                    </div>

                    <div style={{ marginTop: 0, paddingLeft: 20, paddingRight: 20 }}>
                        <TextField
                            label="Auftagsnr. / Rechnungsnr./ Referenz"
                            value={comment}
                            onChange={(e) => onInputSearch(e.target.value)}
                            fullWidth
                        />
                    </div>



                    <hr style={{ marginTop: 15, color: '#004f9f' }}></hr>
                    {loading ? (
                        <div style={{ textAlign: "center", marginTop: "10vh" }}>
                            <CircularProgress />
                        </div>
                    ) : (
                        <>
                            {noResult ? (
                                <div style={{ textAlign: "center", marginTop: "10vh" }} >
                                    Kein Suchergebnis
                                </div>
                            ) : (
                                <>
                                    {arrAssignments.map((a) => {
                                        return (
                                            <Card
                                                key={a.LFD_NR}
                                                elevation={5}
                                                style={cardStyles()}
                                                className={classes.root}
                                                // onClick={() => setAssignment(a)}
                                                onClick={() => setAssignment(a)}
                                            >
                                                <CardContent className={classes.content} style={{ padding: 0, }}>
                                                    <Grid container >
                                                        <Grid item xs={10} style={{ textAlign: "left", paddingTop: 10, paddingBottom: 10, }} >
                                                            <Grid container >
                                                                <Grid item xs={12} style={{ textAlign: "left" }} >
                                                                    <span style={{ fontSize: 18, color: "#004f9f", fontWeight: "bolder" }}>{a.AUFTRAGSNR}</span>
                                                                </Grid>
                                                                <Grid container >
                                                                    {!isTablet ? (
                                                                        <Grid item xs={12} style={{ textAlign: "left" }} >
                                                                            <span style={{ fontSize: 10 }}>Referenz: {a.REFERENZNR}</span> <br />
                                                                            <span style={{ fontSize: 10 }}>Kurzname: {a.KURZNAME}</span> <br />
                                                                            <span style={{ fontSize: 10 }}>Rechnungsnr.: {a.BELEGNR}</span>
                                                                        </Grid>
                                                                    ) : (
                                                                        <>
                                                                            <Grid item xs={8} style={{ textAlign: "left" }} >
                                                                                <span style={{ fontSize: 12 }}>Referenz: {a.REFERENZNR} </span> <br />
                                                                                <span style={{ fontSize: 12 }}>Kurzname: {a.KURZNAME}</span> <br />
                                                                                <span style={{ fontSize: 12 }}>Rechnungsnr.: {a.BELEGNR}</span>
                                                                            </Grid>
                                                                            <Grid item xs={4} style={{ textAlign: "left" }} >
                                                                                <span style={{ fontSize: 12 }}>IDM: {a.IDM}</span> <br />
                                                                                <span style={{ fontSize: 12 }}>Kundennr: {a.KUNDENNR}</span> <br />
                                                                                <span style={{ fontSize: 12 }}>Datum(Beleg): {a.DATUM_BELEG}</span>
                                                                            </Grid>
                                                                        </>
                                                                    )}
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                        <Grid item xs={2} style={{ marginTop: "2em", paddingLeft: "1em" }}>
                                                            <span style={{ fontSize: 30 }}>></span>
                                                        </Grid>
                                                    </Grid>
                                                </CardContent>
                                            </Card>
                                        );
                                    })}
                                </>
                            )
                            }
                        </>
                    )}


                </div>
            </Modal >

        </>
    );
}

export default NewReturnModal;

