import React, { useState, useEffect } from "react";

import Grid from "@material-ui/core/Grid";
import CircularProgress from "@material-ui/core/CircularProgress";

import CookieService from "../services/CookieService";
import CheckAuthService from "../services/CheckAuthService";
import CommentModal from "./neukunden/CommentModal";
import CustomerTypeModal from "./neukunden/CustomerTypeModal";

import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";

import axios from "axios";
import { makeStyles, useTheme } from "@material-ui/core/styles";

import AddCircleIcon from '@material-ui/icons/AddCircle';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  paper: {
    width: "90%",
    height: "30%",
    backgroundColor: theme.palette.background.paper,
    borderRadius: 5,
  },
  paperPositive: {
    width: "90%",
    height: "80%",
    backgroundColor: theme.palette.background.paper,
    borderRadius: 5,
  },
  root: {
    display: "flex",
  },
  clickArea: {
    display: "flex",
  },
  content: {
    flex: 1,
  },
  cover: {
    maxHeight: 100,
    height: "80%",
    width: "30%",
    marginRight: "3%",
  },
  description: {
    minHeight: 40,
    paddingBottom: "5px",
  },
}));

export default function Neukunden({ navigate }) {
  const classes = useStyles();
  const theme = useTheme();
  const [comment, setComment] = useState("");

  const [loading, setLoading] = useState(false);


  const [arrCustomer, setArrCustomer] = useState([]);
  const [clickedModal, setClickedModal] = useState({});

  const [modalCustomerComment, setModalCustomerComment] = useState(false);
  const [modalCustomerType, setModalCustomerType] = useState(false);





  useEffect(() => {
    setLoading(true);
    const accessToken = CookieService.get("access_token");
    if (!accessToken) {
      window.location.replace("/");
    } else {
      CheckAuthService.check();
      getData();
    }
  }, []);

  const getData = () => {
    setLoading(true);
    const bAuth = "Bearer " + CookieService.get("access_token");
    axios
      .get("/customer/get/customer", {
        headers: { Authorization: bAuth },
      })
      .then((res) => {
        if (res.data.code !== 200) {
          alert(res.data.message);
          setLoading(false);
        } else {
          setArrCustomer(res.data.allCustomers);
          setLoading(false);
        }
      })
      .catch((err) => {
        //GEHT NICHT HANDLING!
      });
  };




  const cardStyles = () => {
    return { margin: theme.spacing(2) };
  };

  const getCurrentStatus = (status, type) => {
    if (type === "progress") {
      if (status === "active") {
        return "in Bearbeitung";
      } else {
        return "erledigt";
      }
    } else {
      if (status === "active") {
        return "orange";
      } else {
        return "green";
      }
    }
  }

  const closeModalComment = () => {
    setModalCustomerComment(false);
  }

  const closeModalType = () => {
    setModalCustomerType(false);
  }

  const onClickCard = (model) => {
    setComment(model.comment);
    setModalCustomerComment(true);
    setClickedModal(model);
  }



  return (
    <>
      {loading ? (
        <div style={{ textAlign: "center", marginTop: "45vh" }}>
          <CircularProgress />
        </div>
      ) : (
        <>
          <div
            style={{
              position: "fixed",
              right: "20px",
              bottom: "80px",
              height: "75px",
              width: "75px",
            }}
            onClick={() => setModalCustomerType(true)}
          >
            <AddCircleIcon style={{ color: "#004f9f", fontSize: 75, fontWeight: "bold" }}/>
          </div>
          <CommentModal
            openModal={modalCustomerComment}
            closeModal={() => closeModalComment.bind(this)}
            closeMod={closeModalComment.bind(this)}
            model={clickedModal}
            comment={comment}
            setComment={setComment.bind(this)}
            cookie={CookieService.get("access_token")}
            fetchData={getData.bind(this)}
          />

          <CustomerTypeModal
            openModal={modalCustomerType}
            closeModal={() => closeModalType.bind(this)}
            navigate={navigate}
          />
          <div style={{marginBottom: 80}}>
          {arrCustomer.map((c) => {
            return (
              <Card
                key={c.id}
                elevation={5}
                style={cardStyles()}
                className={classes.root}
                onClick={() => onClickCard(c)}
              >
                <CardContent className={classes.content} style={{ padding: 0, }}>
                  <Grid container >
                    <Grid item xs={1} style={{ backgroundColor: getCurrentStatus(c.status, ""), textAlign: "center" }} >
                    </Grid>
                    <Grid item xs={1}>
                    </Grid>
                    <Grid item xs={10} style={{ textAlign: "left", paddingTop: 10, paddingBottom: 10, }} >
                      <Grid container >
                        <Grid item xs={12} style={{ textAlign: "left" }} >
                          <span style={{ fontSize: 20, color: "#004f9f", fontWeight: "bolder" }}>{c.companyName}</span>
                        </Grid>
                        <Grid item xs={12} style={{ textAlign: "left" }} >
                          <span style={{ fontSize: 14 }}>Status: {getCurrentStatus(c.status, "progress")}</span>
                        </Grid>
                        <Grid item xs={12} style={{ textAlign: "left" }} >
                          <span style={{ fontSize: 14 }}>Knd.Nr: {c.customerNumber ? c.customerNumber : "ausstehend"}</span>
                        </Grid>
                        <Grid item xs={12} style={{ textAlign: "left" }} >
                          <span style={{ fontSize: 14 }}>Bem.: <em>{c.comment ? c.comment : "kein Kommentar"}</em></span>
                        </Grid>

                      </Grid>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            );
          })}
          </div>
        </>
      )
      }
    </>
  );
}
