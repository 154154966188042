import React, { useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import axios from "axios";
import CookieService from "../../services/CookieService";
import CreateRepairPageOne from "./repairPages/page1";
import CreateRepairPageTwo from "./repairPages/page2";

export default function CreateRepair({ open, setOpen, customer, setCustomer }) {
  const [pageControl, setPage] = useState(1);

  const [repairProducts, setRepairProducts] = useState([]);

  function updateContactForm(inputObj) {
    setCustomer(inputObj);
    setPage(pageControl + 1);
  }

  const backstep = () => {
    setPage(pageControl - 1);
  };
  function setOpenClosing() {
    setOpen(false);
    setPage(1);
    setRepairProducts([]);
  }

  const sendFormular = () => {
    customer.products = repairProducts;

    const bAuth = "Bearer " + CookieService.get("access_token");
    const req = {
      repairProduct: customer,
    };

    axios
      .post("/repairs/set/new/repair", req, {
        headers: { Authorization: bAuth },
      })
      .then((res) => {
        if (res.data.code !== 200) {
          alert(
            "Es ist etwas schief gelaufen, versuchen Sie es später erneut."
          );
        } else {
          setOpenClosing();
          alert("Reparatur erfolgreich eingereicht.");
        }
      })
      .catch((err) => {
        console.log(err);
        alert("Es ist etwas schief gelaufen, versuchen Sie es später erneut.");
      });
  };

  return (
    <Dialog
      style={{ maxWidth: "100vw" }}
      open={open}
      // onClose={handleClose}
      scroll={"paper"}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
    >
      <DialogTitle id="scroll-dialog-title">
        Neue Reparatur ({customer.customerNumber}){" "}
      </DialogTitle>
      {/* <DialogContent dividers={scroll === 'paper'}> */}
      <DialogContent dividers={true}>
        {pageControl === 1 ? (
          <CreateRepairPageOne
            customer={customer}
            updateContactForm={(a) => updateContactForm(a)}
            setOpenClosing={() => setOpenClosing()}
          />
        ) : (
          <CreateRepairPageTwo
            backStep={() => backstep()}
            repairProducts={repairProducts}
            setRepairProducts={(a) => setRepairProducts(a)}
            sendFormular={() => sendFormular()}
          />
        )}
      </DialogContent>
    </Dialog>
  );
}
