import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import { Modal } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import TextField from "@material-ui/core/TextField";
import axios from "axios";




const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  paper: {
    width: "90%",
    height: "30%",
    backgroundColor: theme.palette.background.paper,
    borderRadius: 5,
  },
  paperPositive: {
    width: "90%",
    height: "80%",
    backgroundColor: theme.palette.background.paper,
    borderRadius: 5,
  },
  root: {
    display: "flex",
  },
  clickArea: {
    display: "flex",
  },
  content: {
    flex: 1,
  },
  cover: {
    maxHeight: 100,
    height: "80%",
    width: "30%",
    marginRight: "3%",
  },
  description: {
    minHeight: 40,
    paddingBottom: "5px",
  },
}));

function CommentModalRepair({ openModal, closeModal, closeMod, model, comment, setComment, cookie, fetchData }) {
  const classes = useStyles();
  const [loadingEditingComment, setLoadingEditingComment] = useState(false);


  const changeComment = () => {
    setLoadingEditingComment(true);
    const bAuth = "Bearer " + cookie;
    const req = {
      comment: comment,
      modelId: model.id,
    };
    axios
      .post("/repairs/set/comment", req, {
        headers: { Authorization: bAuth },
      })
      .then((res) => {
        if (res.data.code !== 200) {
          setLoadingEditingComment(false);

        } else {
          closeMod();
          fetchData();
          setLoadingEditingComment(false);
        }
      })
      .catch((err) => {
        // console.log(err);
        setLoadingEditingComment(false);
        alert("Es ist etwas schief gelaufen, versuchen Sie es später erneut.")
      });
  }

  return (
    <Modal
      open={openModal}
      onClose={closeModal()}
      aria-labelledby="modal für Kommentar-Änderungen"
      aria-describedby="Ändere den Kommentar"
      className={classes.modal}
    >
      <div className={classes.paperPositive} style={{ paddingTop: 10 }}>
        <div style={{ textAlign: "right", marginRight: 15 }}>
          <CloseIcon
            className={classes.icon}
            onClick={closeModal()}
            style={{ paddingLeft: "45px" }}
          />
        </div>

        <div style={{ marginTop: 40, textAlign: "center", fontSize: 20 }}>
          Angezeigte Bemerkung <br /><br />
          <small style={{ fontSize: 12, color: "green", padding: 15 }}>Die Bemerkung ist für den bearbeitenden IDM zu sehen.</small>
        </div>

        <div style={{ marginTop: 40, padding: 20 }}>
          <TextField
            required
            label="Kommentar"
            multiline
            rows={5}
            value={comment}
            onChange={(e) => setComment(e.target.value)}
            fullWidth
          />
        </div>
        <div style={{ width: "100%", marginTop: "15%", textAlign: "center" }}>
          {!loadingEditingComment ? (
            <Button
              variant="contained"
              color="primary"
              onClick={() => changeComment()}
            >
              Bestätigung senden
            </Button>
          ) : (
            <Button
              disabled
              variant="contained"
              color="primary"
              onClick={() => console.log("test")}
            >
              sendet...
            </Button>
          )}
        </div>
      </div>
    </Modal>
  );
}

export default CommentModalRepair;

