import React, { useState } from "react";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import validator from "validator";
import CookieService from "../services/CookieService";
import axios from "axios";
import DBHandle from '../services/DBHandling';


function LoginForm() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loadingForm, setLoadingForm] = useState(false);
  const [errors, setErrors] = useState("");
  const [errCode, setErrCode] = useState("");


  async function storeUser(curUser) {
    if (!window.indexedDB) {
      console.log("Ihr Browser unterstützt keine stabile Version von IndexedDB. Dieses und jenes Feature wird Ihnen nicht zur Verfügung stehen.");
    } else {
      var user = {
        user_id: curUser.id,
        name: curUser.name,
        prename: curUser.prename,
        email: curUser.email,
        handy: curUser.handy
      };

      const curuser = await DBHandle.loadUser();
      if (curuser) {
        DBHandle.deleteUser(curuser.id);
      }

      DBHandle.addUser(user);

    }
  }

  function _sendForm() {
    let err = {};
    let formIsValid = true;
    if (!validator.isEmail(email)) {
      err.email = "Bitte geben Sie eine gültige E-Mail-Adresse an.";
      formIsValid = false;
    }
    if (!password) {
      err.password = "Bitte geben Sie ein Passwort ein.";
      formIsValid = false;
    }

    if (formIsValid) {
      _formSubmit();
    } else {
      setErrors(err);
    }
  }

  function _formSubmit() {
    setLoadingForm(true);
    const req = {
      email: email,
      password: password
    };
    axios
      .post("/user/login", req)
      .then((res) => {
        if (res.data.code !== 200) {
          setErrCode(res.data.message);
          console.log(res.data);
          alert("Ihre eingegebenen Zugangsdaten sind ungültig. Bitte versuchen Sie es erneut.");
          setLoadingForm(false);
        } else {

          console.log(res);
          const response = res.data.access_token; // von bakcend
          const options = { path: "/", maxAge: 31556926 }; //cookie für alle routes verfügbar / 1 jahr dann expire
          CookieService.set("access_token", response, options);
          storeUser(res.data.user);
          window.location.reload();
        }
      })
      .catch((err) => {
        console.log(err);
        setErrCode(
          "Keine Internetverbindung, versuchen Sie es später erneut."
        );
        setLoadingForm(false);
      });
  }


  return (
    <React.Fragment>
      <span>{errCode}</span>
      <Grid container spacing={3} style={{ marginTop: "20vh" }}>
        <Grid item xs={1} />
        <Grid item xs={10}>
          <TextField
            required
            type="email"
            label="E-Mail-Adresse"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            fullWidth
          />
          <span style={{ color: "red" }}>{errors.email}</span>
        </Grid>
        <Grid item xs={1} />

        <Grid item xs={1} />
        <Grid item xs={10}>
          <TextField
            required
            label="Passwort"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            fullWidth
          />
          <span style={{ color: "red" }}>{errors.password}</span>
        </Grid>
        <Grid item xs={1} />


        <Grid item xs={12} style={{ marginTop: 15, textAlign: "center" }}>
          {!loadingForm ? (
            <Button
              variant="contained"
              color="primary"
              onClick={() => _sendForm()}
            >
              einloggen
            </Button>
          ) : (
            <Button variant="contained" color="primary" disabled>
              warten...
            </Button>
          )}
        </Grid>
      </Grid>
    </React.Fragment>
  );
};


export default LoginForm;
