import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Grid from '@material-ui/core/Grid';
import validator from 'validator';
import Select from '@material-ui/core/Select';

import TextField from '@material-ui/core/TextField';

export default function CreateRepairPageOne({
  customer,
  updateContactForm,
  setOpenClosing,
}) {
  const [customerName, setCustomerName] = useState(
    customer.fullName ?? customer.name1 + customer.name2,
  );
  const [street, setStreet] = useState(customer.street);
  const [customerNumber] = useState(customer.customerNumber);
  const [postalcode, setPostalcode] = useState(customer.postalcode);
  const [city, setCity] = useState(customer.city);
  const [email, setEmail] = useState(customer.email);
  const [telephone, setTelephone] = useState(customer.telephone1);
  const [contact, setContact] = useState(customer.contact ?? '');
  const [invoice, setInvoice] = useState(customer.invoice ?? '');
  const [ware, setWare] = useState(customer.ware ?? 'Abholung beim Kunden');
  const [errors, setErrors] = useState('');

  const validateForm = () => {
    let err = {};
    let isValid = true;

    customerName === '' &&
      (err.customerName = 'Bitte tragen Sie die Kundennummer ein.');
    street === '' && (err.street = 'Bitte tragen Sie die Straße ein.');
    postalcode === '' && (err.postalcode = 'Bitte tragen Sie die PLZ ein.');
    city === '' && (err.city = 'Bitte tragen Sie die Stadt ein.');
    telephone === '' &&
      (err.telephone = 'Bitte tragen Sie die Telefonnummer ein.');
    contact === '' &&
      (err.contact = 'Bitte tragen Sie den Ansprechpartner ein.');

    !validator.isEmail(email) &&
      (err.email = 'Bitte geben Sie eine gültige E-Mail-Adresse an');

    Object.keys(err).length !== 0 && (isValid = false);

    if (isValid) {
      setErrors([]);
      const obj = {
        fullName: customerName,
        street,
        postalcode,
        email,
        city,
        customerNumber,
        telephone1: telephone,
        contact,
        invoice,
        ware,
      };
      updateContactForm(obj);
    } else {
      setErrors(err);
    }
  };

  return (
    <Grid container>
      <h4 style={{ marginBottom: 5 }}>Kunde</h4>
      <Grid item xs={12} style={{ textAlign: 'left' }}>
        <TextField
          required
          type="text"
          label="Firma"
          style={{ marginTop: 10 }}
          value={customerName}
          onChange={(e) => setCustomerName(e.target.value)}
          fullWidth
        />
        <span style={{ color: 'red' }}>{errors.customer}</span>
      </Grid>
      <Grid item xs={12} style={{ textAlign: 'left', marginTop: 10 }}>
        <TextField
          required
          type="text"
          label="Straße und Hausnr."
          style={{ marginTop: 10 }}
          value={street}
          onChange={(e) => setStreet(e.target.value)}
          fullWidth
        />
        <span style={{ color: 'red' }}>{errors.street}</span>
      </Grid>

      <Grid item xs={3} style={{ textAlign: 'left', marginTop: 10 }}>
        <TextField
          required
          type="text"
          label="PLZ"
          style={{ marginTop: 10 }}
          value={postalcode}
          onChange={(e) => setPostalcode(e.target.value)}
          fullWidth
        />
        <span style={{ color: 'red' }}>{errors.plz}</span>
      </Grid>
      <Grid item xs={1} style={{ textAlign: 'left', marginTop: 10 }}></Grid>
      <Grid item xs={8} style={{ textAlign: 'left', marginTop: 10 }}>
        <TextField
          required
          type="text"
          label="Stadt"
          style={{ marginTop: 10 }}
          value={city}
          onChange={(e) => setCity(e.target.value)}
          fullWidth
        />
        <span style={{ color: 'red' }}>{errors.city}</span>
      </Grid>

      <Grid item xs={12} style={{ textAlign: 'left', marginTop: 10 }}>
        <TextField
          required
          type="email"
          label="E-Mail"
          style={{ marginTop: 10 }}
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          fullWidth
        />
        <span style={{ color: 'red' }}>{errors.email}</span>
      </Grid>

      <Grid item xs={12} style={{ textAlign: 'left', marginTop: 10 }}>
        <TextField
          required
          type="text"
          label="Telefon"
          style={{ marginTop: 10 }}
          value={telephone}
          onChange={(e) => setTelephone(e.target.value)}
          fullWidth
        />
        <span style={{ color: 'red' }}>{errors.telephone}</span>
      </Grid>

      <Grid item xs={12} style={{ textAlign: 'left', marginTop: 10 }}>
        <TextField
          required
          type="text"
          label="Ansprechpartner"
          style={{ marginTop: 10 }}
          value={contact}
          onChange={(e) => setContact(e.target.value)}
          fullWidth
        />
        <span style={{ color: 'red' }}>{errors.contact}</span>
      </Grid>

      <Grid
        item
        xs={12}
        style={{ textAlign: 'left', marginTop: 10, marginBottom: 10 }}
      >
        <TextField
          type="text"
          label="Rechnungsnr."
          style={{ marginTop: 10 }}
          value={invoice}
          onChange={(e) => setInvoice(e.target.value)}
          fullWidth
        />
      </Grid>
      <Select
        native
        style={{ width: '100%', marginTop: 25 }}
        value={ware}
        onChange={(e) => setWare(e.target.value)}
        name="ware"
        inputProps={{
          id: 'age-native-required',
        }}
      >
        <option value={'Abholung beim Kunden'}>Abholung beim Kunden</option>
        <option value={'Kunde bringt Gerät'}>Kunde bringt Gerät</option>
      </Select>
      <Grid item xs={12} style={{ textAlign: 'right', marginTop: 10 }}>
        <table style={{ width: '100%' }}>
          <tbody>
            <tr>
              <td style={{ width: '50%', textAlign: 'left' }}>
                <Button
                  variant="outlined"
                  onClick={() => setOpenClosing()}
                  color="error"
                >
                  schließen
                </Button>
              </td>
              <td style={{ width: '50%', textAlign: 'right' }}>
                <Button
                  variant="outlined"
                  onClick={() => validateForm()}
                  color="success"
                >
                  weiter
                </Button>
              </td>
            </tr>
          </tbody>
        </table>
      </Grid>
    </Grid>
  );
}
