import db from '../db';

class DBHandling {
    async loadUser() {

        const ret = await db.table('users')
            .orderBy(':id')
            .first();
        return ret;
    }

    addUser(user) {
        db.table('users')
            .add(user);
    }

    deleteUser(id) {
        db.table('users')
            .delete(id);
    }

    updateUser(id, user) {
        db.table('users')
            .update(id, user);
    }
}
export default new DBHandling();