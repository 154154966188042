import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';

import CookieService from '../../services/CookieService';
import CheckAuthService from '../../services/CheckAuthService';

import axios from 'axios';
import { makeStyles } from '@material-ui/core/styles';

import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';

const useStyles = makeStyles((theme) => ({
  col: {
    padding: 10,
    marginTop: 20,
  },
}));

export default function NeukundenFormBusiness({ type }) {
  const classes = useStyles();

  const [loading, setLoading] = useState(false);
  const [loadingSendingForm, setLoadingSendingForm] = useState(false);
  const [errors, setErrors] = useState('');
  const [currentUser, setCurrentUser] = useState('');

  const [companyName, setCompanyName] = useState('');
  const [companyStreet, setCompanyStreet] = useState('');
  const [companyPostalcode, setCompanyPostalcode] = useState('');
  const [companyCity, setCompanyCity] = useState('');
  const [companyTelephone, setCompanyTelephone] = useState('');
  const [companyMobile, setCompanyMobile] = useState('');
  const [companyFax, setCompanyFax] = useState('');
  const [companyEmail, setCompanyEmail] = useState('');
  const [companyPaymentCondition, setCompanyPaymentCondition] =
    useState('Bitte auswählen!'); //CUstom kommt dazu wenn Sonstiges lul
  const [companyCustomPaymentCondition, setCompanyCustomPaymentCondition] =
    useState(''); // das ist custom!!
  const [companyWishedSepa, setCompanyWishedSepa] = useState('nein');
  const [companyBankAccount, setCompanyBankAccount] = useState('');
  const [companyBankBIC, setcompanyBankBIC] = useState('');
  const [companyBankIBAN, setCompanyBankIBAN] = useState('');
  const [otherDeliveryAdress, setOtherDeliveryAdress] = useState('nein');
  const [otherDeliveryAdressName, setOtherDeliveryAdressName] = useState('');
  const [otherDeliveryAdressStreet, setOtherDeliveryAdressStreet] =
    useState('');
  const [otherDeliveryAdressPostalcode, setOtherDeliveryAdressPostalcode] =
    useState('');
  const [otherDeliveryAdressCity, setOtherDeliveryAdressCity] = useState('');
  const [invoiceEmail, setInvoiceEmail] = useState('');

  useEffect(() => {
    setLoading(true);
    const accessToken = CookieService.get('access_token');
    if (!accessToken) {
      window.location.replace('/');
    } else {
      CheckAuthService.check();
      getAuth();
    }
  }, []);

  const getAuth = () => {
    const bAuth = 'Bearer ' + CookieService.get('access_token');
    axios
      .get('/user/auth/get', {
        headers: { Authorization: bAuth },
      })
      .then((res) => {
        if (res.data.code !== 200) {
          CookieService.remove('access_token');
          window.location.replace('/');
        } else {
          setCurrentUser(res.data.user);
          setLoading(false);
        }
      })
      .catch((err) => {
        //GEHT NICHT HANDLING!
      });
  };

  const validateForm = () => {
    setLoadingSendingForm(true);
    let err = {};
    let isValid = true;

    if (!companyName) {
      err.companyName = 'Name muss ausgefüllt sein';
      isValid = false;
    }
    if (!companyStreet) {
      err.companyStreet = 'Straße muss ausgefüllt sein';
      isValid = false;
    }
    if (!companyPostalcode) {
      err.companyPostalcode = 'PLZ muss ausgefüllt sein';
      isValid = false;
    }
    if (!companyCity) {
      err.companyCity = 'Stadt muss ausgefüllt sein';
      isValid = false;
    }
    if (!companyTelephone) {
      err.companyTelephone = 'Telefon muss ausgefüllt sein';
      isValid = false;
    }

    if (companyPaymentCondition === 'Bitte auswählen!') {
      err.companyPaymentCondition = 'Bitte Auswahl tätigen!';
      isValid = false;
    }

    if (companyPaymentCondition === 'Sonstiges') {
      if (!companyCustomPaymentCondition) {
        err.companyCustomPaymentCondition =
          'Zahlungsmethode muss ausgefüllt werden';
        isValid = false;
      }
    }

    if (companyWishedSepa === 'ja') {
      if (!companyBankAccount) {
        err.companyBankAccount = 'Name der Bank muss ausgefüllt sein';
        isValid = false;
      }
      if (!companyBankBIC) {
        err.companyBankBIC = 'BIC muss ausgefüllt sein';
        isValid = false;
      }
      if (!companyBankIBAN) {
        err.companyBankIBAN = 'IBAN muss ausgefüllt sein';
        isValid = false;
      }
    }
    if (otherDeliveryAdress === 'ja') {
      if (!otherDeliveryAdressName) {
        err.otherDeliveryAdressName = 'Adresse muss ausgefüllt sein';
        isValid = false;
      }
      if (!otherDeliveryAdressStreet) {
        err.otherDeliveryAdressStreet = 'Straße muss ausgefüllt sein';
        isValid = false;
      }
      if (!otherDeliveryAdressPostalcode) {
        err.otherDeliveryAdressPostalcode = 'PLZ muss ausgefüllt sein';
        isValid = false;
      }
      if (!otherDeliveryAdressCity) {
        err.otherDeliveryAdressCity = 'Stadt muss ausgefüllt sein';
        isValid = false;
      }
    }

    if (isValid) {
      sendForm();
    } else {
      setLoadingSendingForm(false);
      setErrors(err);
    }
  };

  const sendForm = () => {
    const bAuth = 'Bearer ' + CookieService.get('access_token');
    const req = {
      companyName: companyName,
      companyStreet: companyStreet,
      companyPostalcode: companyPostalcode,
      companyCity: companyCity,
      companyTelephone: companyTelephone,
      companyMobile: companyMobile,
      companyFax: companyFax,
      companyNumberOfEmployees: null,
      companyUstId: null,
      companyBranche: null,
      companyConzern: null,
      companyDiscountGroup: null,
      companyEmailGelangensbestaetigung: null,
      companyHomepage: null,
      companyEmail: companyEmail,
      companyShopWanted: null,
      companyPaymentCondition: companyPaymentCondition,
      companyCustomPaymentCondition: companyCustomPaymentCondition,
      companyWishedSepa: companyWishedSepa,
      companyBankAccount: companyBankAccount,
      companyBankBIC: companyBankBIC,
      companyBankIBAN: companyBankIBAN,
      otherDeliveryAdress: otherDeliveryAdress,
      otherDeliveryAdressName: otherDeliveryAdressName,
      otherDeliveryAdressStreet: otherDeliveryAdressStreet,
      otherDeliveryAdressPostalcode: otherDeliveryAdressPostalcode,
      otherDeliveryAdressCity: otherDeliveryAdressCity,
      contactSex: null,
      contactName: null,
      contactPrename: null,
      contactTelefon: null,
      contactFax: null,
      contactEmail: null,
      contactDepartment: null,
      contactFunction: null,
      customerIDM1: 'se',
      customerIDM2: null,
      customerADM: currentUser.prename + ' ' + currentUser.name,
      invoiceEmail: invoiceEmail,
      infoEmail: '-',
      interestWare: '-',
    };
    axios
      .post('/customer/set/new/customer/private', req, {
        headers: { Authorization: bAuth },
      })
      .then((res) => {
        if (res.data.code !== 200) {
          console.log(res.data.message);
          setLoadingSendingForm(false);
        } else {
          setLoadingSendingForm(false);
          alert('Anlage erfolgreich eingereicht.');
          window.location.replace('/');
        }
      })
      .catch((err) => {
        console.log(err);
        setLoadingSendingForm(false);
        alert('Es ist etwas schief gelaufen, versuchen Sie es später erneut.');
      });
  };

  return (
    <>
      {loading ? (
        <div style={{ textAlign: 'center', marginTop: '45vh' }}>
          <CircularProgress />
        </div>
      ) : (
        <>
          <Grid container style={{ marginBottom: 100 }}>
            <Grid item xs={1}></Grid>
            <Grid item xs={10} container style={{ marginTop: '2vh' }}>
              <Grid item xs={12} className={classes.col}>
                <span style={{ fontSize: 24, color: '#004f9f' }}>
                  Neuer Privatkunde
                </span>
              </Grid>
              <Grid item xs={12} className={classes.col}>
                <span style={{ fontSize: 18, color: '#000' }}>
                  Informationen Kunde
                </span>
              </Grid>
              <Grid item xs={12} sm={6} className={classes.col}>
                <TextField
                  required
                  type="text"
                  label="Name"
                  value={companyName}
                  onChange={(e) => setCompanyName(e.target.value)}
                  fullWidth
                />
                <span style={{ color: 'red' }}>{errors.companyName}</span>
              </Grid>
              <Grid item xs={12} sm={6} className={classes.col}>
                <TextField
                  required
                  type="text"
                  label="Straße"
                  value={companyStreet}
                  onChange={(e) => setCompanyStreet(e.target.value)}
                  fullWidth
                />
                <span style={{ color: 'red' }}>{errors.companyStreet}</span>
              </Grid>
              <Grid item xs={12} sm={6} className={classes.col}>
                <TextField
                  required
                  type="text"
                  label="Postleitzahl"
                  value={companyPostalcode}
                  onChange={(e) => setCompanyPostalcode(e.target.value)}
                  fullWidth
                />
                <span style={{ color: 'red' }}>{errors.companyPostalcode}</span>
              </Grid>
              <Grid item xs={12} sm={6} className={classes.col}>
                <TextField
                  required
                  type="text"
                  label="Stadt"
                  value={companyCity}
                  onChange={(e) => setCompanyCity(e.target.value)}
                  fullWidth
                />
                <span style={{ color: 'red' }}>{errors.companyCity}</span>
              </Grid>
              <Grid item xs={12} sm={6} className={classes.col}>
                <TextField
                  required
                  type="text"
                  label="Telefon"
                  value={companyTelephone}
                  onChange={(e) => setCompanyTelephone(e.target.value)}
                  fullWidth
                />
                <span style={{ color: 'red' }}>{errors.companyTelephone}</span>
              </Grid>
              <Grid item xs={12} sm={6} className={classes.col}>
                <TextField
                  type="text"
                  label="Handy"
                  value={companyMobile}
                  onChange={(e) => setCompanyMobile(e.target.value)}
                  fullWidth
                />
                <span style={{ color: 'red' }}>{errors.companyMobile}</span>
              </Grid>
              <Grid item xs={12} sm={6} className={classes.col}>
                <TextField
                  type="text"
                  label="Fax"
                  value={companyFax}
                  onChange={(e) => setCompanyFax(e.target.value)}
                  fullWidth
                />
                <span style={{ color: 'red' }}>{errors.companyFax}</span>
              </Grid>

              <Grid item xs={12} sm={6} className={classes.col}>
                <TextField
                  type="text"
                  label="E-Mail"
                  value={companyEmail}
                  onChange={(e) => setCompanyEmail(e.target.value)}
                  fullWidth
                />
                <span style={{ color: 'red' }}>{errors.companyEmail}</span>
              </Grid>

              <Grid item xs={12} sm={6} className={classes.col}>
                <InputLabel htmlFor="age-native-required">
                  Rabattgruppe Firma
                </InputLabel>
                <Select
                  native
                  style={{ width: '100%' }}
                  value={companyPaymentCondition}
                  onChange={(e) => setCompanyPaymentCondition(e.target.value)}
                  name="companyPaymentCondition"
                  inputProps={{
                    id: 'age-native-required',
                  }}
                >
                  <option value={'Bitte auswählen!'}>Bitte auswählen!</option>
                  <option value={'8 Tage netto'}>8 Tage netto</option>
                  <option value={'Barzahlung bei Erhalt der Ware'}>
                    Barzahlung bei Erhalt der Ware
                  </option>
                  <option value={'8 Tage 2% / 30 Tage netto'}>
                    8 Tage 2% / 30 Tage netto
                  </option>
                  <option value={'Sonstiges'}>Sonstiges</option>
                </Select>
                <span style={{ color: 'red' }}>
                  {errors.companyPaymentCondition}
                </span>
              </Grid>

              <Grid item xs={12} sm={6} className={classes.col}>
                {(() => {
                  if (companyPaymentCondition === 'Sonstiges') {
                    return (
                      <>
                        <TextField
                          required
                          type="text"
                          label="Sonstige Kondition Kunde"
                          value={companyCustomPaymentCondition}
                          onChange={(e) =>
                            setCompanyCustomPaymentCondition(e.target.value)
                          }
                          fullWidth
                        />
                        <span style={{ color: 'red' }}>
                          {errors.companyCustomPaymentCondition}
                        </span>
                      </>
                    );
                  } else {
                    return null;
                  }
                })()}
              </Grid>
              <Grid item xs={12} sm={12} className={classes.col}>
                <FormLabel component="legend">
                  Wünscht der Kunde SEPA?
                </FormLabel>
                <RadioGroup
                  row
                  aria-label="position"
                  value={companyWishedSepa}
                  onChange={(e) => setCompanyWishedSepa(e.target.value)}
                >
                  <FormControlLabel
                    value="ja"
                    control={<Radio color="primary" />}
                    label="ja"
                    labelPlacement="start"
                  />
                  <FormControlLabel
                    value="nein"
                    control={<Radio color="primary" />}
                    label="nein"
                    labelPlacement="start"
                  />
                </RadioGroup>
                <span style={{ color: 'red' }}>{errors.companyWishedSepa}</span>
              </Grid>

              {(() => {
                if (companyWishedSepa === 'ja') {
                  return (
                    <>
                      <Grid item xs={12} sm={12} className={classes.col}>
                        <TextField
                          required
                          type="text"
                          label="Bank Kunde"
                          value={companyBankAccount}
                          onChange={(e) =>
                            setCompanyBankAccount(e.target.value)
                          }
                          fullWidth
                        />
                        <span style={{ color: 'red' }}>
                          {errors.companyBankAccount}
                        </span>
                      </Grid>
                      <Grid item xs={12} sm={6} className={classes.col}>
                        <TextField
                          required
                          type="text"
                          label="BIC Kunde"
                          value={companyBankBIC}
                          onChange={(e) => setcompanyBankBIC(e.target.value)}
                          fullWidth
                        />
                        <span style={{ color: 'red' }}>
                          {errors.companyBankBIC}
                        </span>
                      </Grid>
                      <Grid item xs={12} sm={6} className={classes.col}>
                        <TextField
                          required
                          type="text"
                          label="IBAN Kunde"
                          value={companyBankIBAN}
                          onChange={(e) => setCompanyBankIBAN(e.target.value)}
                          fullWidth
                        />
                        <span style={{ color: 'red' }}>
                          {errors.companyBankIBAN}
                        </span>
                      </Grid>
                    </>
                  );
                } else {
                  return null;
                }
              })()}

              <Grid item xs={12} sm={12} className={classes.col}>
                <FormLabel component="legend">
                  Andere Lieferadresse gewünscht?
                </FormLabel>
                <RadioGroup
                  row
                  aria-label="position"
                  value={otherDeliveryAdress}
                  onChange={(e) => setOtherDeliveryAdress(e.target.value)}
                >
                  <FormControlLabel
                    value="ja"
                    control={<Radio color="primary" />}
                    label="ja"
                    labelPlacement="start"
                  />
                  <FormControlLabel
                    value="nein"
                    control={<Radio color="primary" />}
                    label="nein"
                    labelPlacement="start"
                  />
                </RadioGroup>
                <span style={{ color: 'red' }}>
                  {errors.otherDeliveryAdress}
                </span>
              </Grid>
              {(() => {
                if (otherDeliveryAdress === 'ja') {
                  return (
                    <>
                      <Grid item xs={12} sm={12} className={classes.col}>
                        <TextField
                          required
                          type="text"
                          label="Name von anderer Lieferanschrift"
                          value={otherDeliveryAdressName}
                          onChange={(e) =>
                            setOtherDeliveryAdressName(e.target.value)
                          }
                          fullWidth
                        />
                        <span style={{ color: 'red' }}>
                          {errors.otherDeliveryAdressName}
                        </span>
                      </Grid>
                      <Grid item xs={12} sm={12} className={classes.col}>
                        <TextField
                          required
                          type="text"
                          label="Straße von anderer Lieferanschrift"
                          value={otherDeliveryAdressStreet}
                          onChange={(e) =>
                            setOtherDeliveryAdressStreet(e.target.value)
                          }
                          fullWidth
                        />
                        <span style={{ color: 'red' }}>
                          {errors.otherDeliveryAdressStreet}
                        </span>
                      </Grid>
                      <Grid item xs={12} sm={6} className={classes.col}>
                        <TextField
                          required
                          type="text"
                          label="PLZ von anderer Lieferanschrift"
                          value={otherDeliveryAdressPostalcode}
                          onChange={(e) =>
                            setOtherDeliveryAdressPostalcode(e.target.value)
                          }
                          fullWidth
                        />
                        <span style={{ color: 'red' }}>
                          {errors.otherDeliveryAdressPostalcode}
                        </span>
                      </Grid>
                      <Grid item xs={12} sm={6} className={classes.col}>
                        <TextField
                          required
                          type="text"
                          label="Stadt von anderer Lieferanschrift"
                          value={otherDeliveryAdressCity}
                          onChange={(e) =>
                            setOtherDeliveryAdressCity(e.target.value)
                          }
                          fullWidth
                        />
                        <span style={{ color: 'red' }}>
                          {errors.otherDeliveryAdressCity}
                        </span>
                      </Grid>
                    </>
                  );
                } else {
                  return null;
                }
              })()}

              <Grid item xs={12} sm={6} className={classes.col}>
                <TextField
                  required
                  disabled
                  type="text"
                  label="Außendienstmitarbeiter"
                  value={currentUser.name + ', ' + currentUser.prename}
                  fullWidth
                />
                <span style={{ color: 'red' }}>{errors.customerADM}</span>
              </Grid>
              <Grid item xs={12} sm={6} className={classes.col}>
                <TextField
                  type="text"
                  label="E-Mail Rechnungsversand"
                  value={invoiceEmail}
                  onChange={(e) => setInvoiceEmail(e.target.value)}
                  fullWidth
                />
                <span style={{ color: 'red' }}>{errors.invoiceEmail}</span>
              </Grid>

              <Grid
                item
                xs={12}
                className={classes.col}
                style={{ textAlign: 'center', marginTop: 20 }}
              >
                {!loadingSendingForm ? (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => validateForm()}
                  >
                    Kunde anlegen
                  </Button>
                ) : (
                  <Button
                    disabled
                    variant="contained"
                    color="primary"
                    onClick={() => console.log('test')}
                  >
                    sendet...
                  </Button>
                )}
              </Grid>
            </Grid>
            <Grid item xs={1}></Grid>
          </Grid>
        </>
      )}
    </>
  );
}
