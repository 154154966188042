import React, { useState, useEffect } from "react";
import VCard from 'vcard-creator'
import QRCode from "react-qr-code";
import Grid from "@material-ui/core/Grid";

import CircularProgress from "@material-ui/core/CircularProgress";
import CookieService from "../services/CookieService";
import CheckAuthService from "../services/CheckAuthService";
import axios from "axios";

import logo from '../assets/logo/albw_transparent.png';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';

import DBHandle from '../services/DBHandling';
import RefreshIcon from '@material-ui/icons/Refresh';



export default function Profile() {
  const [vCard, setVCard] = useState("");

  const [currentUser, setCurrentUser] = useState({});

  const [loading, setLoading] = useState(true);


  useEffect(() => {
    const accessToken = CookieService.get("access_token");
    if (!accessToken) {
      window.location.replace("/");
    } else {
      CheckAuthService.check();
      if (!currentUser.qr) {
        getUser();
      }
    }
    // eslint-disable-next-line
  }, [currentUser]);


  const getData = () => {
    setLoading(true);
    const bAuth = "Bearer " + CookieService.get("access_token");
    axios
      .get("/user/auth/get", {
        headers: { Authorization: bAuth },
      })
      .then((res) => {
        if (res.data.code !== 200) {
          CookieService.remove("access_token");
          window.location.replace("/");
        } else {
          storeUser(res.data.user);
          setLoading(false);
        }
      })
      .catch((err) => {
        //GEHT NICHT HANDLING!
      });
  }

  async function storeUser(curUser) {
    let telephone = "";
    if (curUser.extension) {
      telephone = "072549300" + curUser.extension;
    } else {
      telephone = curUser.handy;
    }
    if (!window.indexedDB) {
      console.log("Ihr Browser unterstützt keine stabile Version von IndexedDB. Dieses und jenes Feature wird Ihnen nicht zur Verfügung stehen.");
    } else {
      var user = {
        user_id: curUser.id,
        name: curUser.name,
        prename: curUser.prename,
        email: curUser.email,
        handy: telephone
      };

      const curuser = await DBHandle.loadUser();
      if (curuser) {
        DBHandle.deleteUser(curuser.id);
      }
      DBHandle.addUser(user);
      setVCardEntry(user);
      setCurrentUser(user);
      updateCacheQR();
    }
  }

  async function getUser() {
    if (!window.indexedDB) {
      console.log("Ihr Browser unterstützt keine stabile Version von IndexedDB. Dieses und jenes Feature wird Ihnen nicht zur Verfügung stehen.");
    } else {
      const curuser = await DBHandle.loadUser();
      if (curuser) {
        setVCardEntry(curuser);
        setCurrentUser(curuser);
      } else {
        getData()
      }
      updateCacheQR();
      setLoading(false);
    }
  }
  async function setVCardEntry(userModel) {
    const myVCard = new VCard()
    myVCard
      // Add personal data
      .addName(userModel.name, userModel.prename)
      // Add work data
      .addCompany('albw Handels GmbH')
      .addJobtitle('Vertrieb')
      .addEmail(userModel.email)
      .addPhoneNumber(userModel.handy, 'PREF;WORK')
      .addAddress("", "", 'Dieselstr. 2', 'Waghäusel', "", '68753', 'Deutschland')
      .addURL('https://www.albw.de')

    setVCard(myVCard.toString());
  }

  function updateCacheQR() {
    if (document.getElementById("test") && currentUser.id) {
      var user = currentUser;
      user.qr = document.getElementById("test");

      const id = currentUser.id;
      DBHandle.updateUser(id, { qr: document.getElementById("test").innerHTML });
    }
  }

  async function logout() {
    const curuser = await DBHandle.loadUser();
    if (curuser) {
      DBHandle.deleteUser(curuser.id);
    }
    CookieService.remove("access_token");
    window.location.replace("/");
  };

  return (
    <>
      {loading ? (
        <div style={{ textAlign: "center", marginTop: "45vh" }}>
          <CircularProgress />
        </div>
      ) : (
        <>
          <React.Fragment>
            <div
              style={{
                backgroundColor: "#004f9f",
                color: "#f4f4f4",
                height: '20vh',
                textAlign: "right"
              }}
            >
              <Grid container >
                <Grid item xs={6} style={{ textAlign: "left" }} >
                  <RefreshIcon
                    fontSize={'large'}
                    style={{ marginTop: 20, marginLeft: 20 }}
                    onClick={() => getData()}
                  />
                </Grid>
                <Grid item xs={6} style={{ textAlign: "right" }} >
                  <ExitToAppIcon
                    fontSize={'large'}
                    style={{ marginTop: 20, marginRight: 20 }}
                    onClick={() => logout()}
                  />
                </Grid>
              </Grid>

            </div>
            <div style={{ width: "100%", textAlign: "center", marginTop: "-12vh", }}>

              <img
                src={logo}
                alt={"albw Logo"}
                style={{
                  backgroundColor: "#ffffff",
                  color: "#f4f4f4",
                  height: 150,
                  width: 150,
                  borderRadius: "100%",
                  objectFit: 'contain',
                }}
              ></img>
            </div>

            <Grid container >
              <Grid item xs={12} style={{ textAlign: "center" }} >
                <span style={{ color: "#004f9f", fontSize: 30 }}>{currentUser.prename} {currentUser.name}</span>
              </Grid>
            </Grid>

            <div style={{ marginBottom: "10vh", marginTop: "8vh", textAlign: "center" }}>
              <QRCode value={vCard} id={"test"} />
              {(() => {
                updateCacheQR();
              })()}
            </div>



          </React.Fragment>
        </>



      )}
    </>
  );
}
