import React from "react";
import { Modal } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import Grid from "@material-ui/core/Grid";
import WorkIcon from '@material-ui/icons/Work';
import EmojiPeopleIcon from '@material-ui/icons/EmojiPeople';






const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  paper: {
    width: "90%",
    height: "30%",
    backgroundColor: theme.palette.background.paper,
    borderRadius: 5,
  },
  paperPositive: {
    width: "90%",
    height: "50%",
    backgroundColor: theme.palette.background.paper,
    borderRadius: 5,
  },
  root: {
    display: "flex",
  },
  clickArea: {
    display: "flex",
  },
  content: {
    flex: 1,
  },
  cover: {
    maxHeight: 100,
    height: "80%",
    width: "30%",
    marginRight: "3%",
  },
  description: {
    minHeight: 40,
    paddingBottom: "5px",
  },
}));

function CustomerTypeModal({ openModal, closeModal, navigate }) {
  const classes = useStyles();

  return (
    <Modal
      open={openModal}
      onClose={closeModal()}
      aria-labelledby="Modal für Entscheidung welche Art Neukunde"
      aria-describedby="Modal für Typ Neukunde"
      className={classes.modal}
    >
      <div className={classes.paperPositive} style={{ paddingTop: 10 }}>
        <div style={{ textAlign: "right", marginRight: 15 }}>
          <CloseIcon
            className={classes.icon}
            onClick={closeModal()}
            style={{ paddingLeft: "45px" }}
          />
        </div>

        <div style={{ marginTop: 40, textAlign: "center", fontSize: 20 }}>
          Wählen Sie den Kundentyp aus<br /><br />
        </div>

        <div style={{ width: "100%", marginTop: "15%", textAlign: "center" }}>
          <Grid container>
            <Grid item xs={6} >
              <div style={{
                border: "1px solid #004f9f",
                borderRadius: 20,
                marginLeft: 10,
                marginRight: 5,
                paddingTop: 20,
                paddingBottom: 20
              }}
                onClick={() => navigate("/neukunde/private")}
              >
                <Grid item xs={12} >
                  <EmojiPeopleIcon fontSize="large" style={{ color: "#004f9f" }}></EmojiPeopleIcon>
                </Grid>
                <Grid item xs={12} style={{ marginTop: 20, color: "#004f9f", fontWeight: "bold" }}  >
                  Privatkunde
                </Grid>
              </div>
            </Grid>

            <Grid item xs={6}>
              <div style={{
                border: "1px solid #004f9f",
                borderRadius: 20,
                marginLeft: 5,
                marginRight: 10,
                paddingTop: 20,
                paddingBottom: 20
              }}
                onClick={() => navigate("/neukunde/business")}
              >

                <Grid item xs={12} >
                  <WorkIcon fontSize="large" style={{ color: "#004f9f" }}></WorkIcon>
                </Grid>
                <Grid item xs={12} style={{ marginTop: 20, color: "#004f9f", fontWeight: "bold" }} >
                  Geschäftskunde
                </Grid>
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
    </Modal >
  );
}

export default CustomerTypeModal;

