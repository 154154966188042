import React, { useState, useEffect } from 'react';

import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';

import CookieService from '../services/CookieService';
import CheckAuthService from '../services/CheckAuthService';
import NewRepairModal from './repairs/NewRepairModal';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';

import axios from 'axios';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import CommentModalRepair from './repairs/CommentModalRepair';

import AddCircleIcon from '@material-ui/icons/AddCircle';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  paper: {
    width: '90%',
    height: '30%',
    backgroundColor: theme.palette.background.paper,
    borderRadius: 5,
  },
  paperPositive: {
    width: '90%',
    height: '80%',
    backgroundColor: theme.palette.background.paper,
    borderRadius: 5,
  },
  root: {
    display: 'flex',
  },
  clickArea: {
    display: 'flex',
  },
  content: {
    flex: 1,
  },
  cover: {
    maxHeight: 100,
    height: '80%',
    width: '30%',
    marginRight: '3%',
  },
  description: {
    minHeight: 40,
    paddingBottom: '5px',
  },
}));

export default function Repairs({ navigate }) {
  const classes = useStyles();
  const theme = useTheme();
  const [comment, setComment] = useState('');

  const [loading, setLoading] = useState(false);

  const [arrRepairs, setArrRepairs] = useState([]);
  const [clickedModal, setClickedModal] = useState({});

  const [modalRepairComment, setModalRepairComment] = useState(false);
  const [modalNewRepair, setModalNewRepair] = useState(false);

  useEffect(() => {
    setLoading(true);
    const accessToken = CookieService.get('access_token');
    if (!accessToken) {
      window.location.replace('/');
    } else {
      CheckAuthService.check();
      getData();
    }
  }, []);

  const getData = () => {
    setLoading(true);
    const bAuth = 'Bearer ' + CookieService.get('access_token');
    axios
      .get('/repairs/get/repairs', {
        headers: { Authorization: bAuth },
      })
      .then((res) => {
        if (res.data.code !== 200) {
          alert(res.data.message);
          setLoading(false);
        } else {
          setArrRepairs(res.data.allRepairs);
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        alert(
          'Fehlende Internetverbindung. Bitte versuchen Sie es zeitnah erneut.',
        );
      });
  };

  const cardStyles = () => {
    return { margin: theme.spacing(2) };
  };

  const getCurrentStatus = (status, type) => {
    if (type === 'progress') {
      if (status === 0) {
        return 'erledigt';
      } else {
        return 'in Bearbeitung';
      }
    } else {
      if (status === 0) {
        return 'green';
      } else {
        return 'orange';
      }
    }
  };

  const closeModalComment = () => {
    setModalRepairComment(false);
  };

  const closeModalType = () => {
    setModalNewRepair(false);
  };

  const onClickCard = (model) => {
    setComment(model.comment);
    setModalRepairComment(true);
    setClickedModal(model);
  };

  return (
    <>
      {loading ? (
        <div style={{ textAlign: 'center', marginTop: '45vh' }}>
          <CircularProgress />
        </div>
      ) : (
        <>
          <div
            style={{
              position: 'fixed',
              right: '20px',
              bottom: '80px',
              height: '75px',
              width: '75px',
            }}
            onClick={() => setModalNewRepair(true)}
          >
            <AddCircleIcon
              style={{ color: '#004f9f', fontSize: 75, fontWeight: 'bold' }}
            />
          </div>
          <CommentModalRepair
            openModal={modalRepairComment}
            closeModal={() => closeModalComment.bind(this)}
            closeMod={closeModalComment.bind(this)}
            model={clickedModal}
            comment={comment}
            setComment={setComment.bind(this)}
            cookie={CookieService.get('access_token')}
            fetchData={getData.bind(this)}
          />

          <NewRepairModal
            openModal={modalNewRepair}
            closeModal={() => closeModalType.bind(this)}
            navigate={navigate}
            fetchData={getData.bind(this)}
          />

          <div style={{ marginBottom: 80 }}>
            {arrRepairs.map((p) => {
              console.log(p);
              return (
                <Card
                  key={p.id}
                  elevation={5}
                  style={cardStyles()}
                  className={classes.root}
                  onClick={() => onClickCard(p)}
                >
                  <CardContent
                    className={classes.content}
                    style={{ padding: 0 }}
                  >
                    <Grid container>
                      <Grid
                        item
                        xs={1}
                        style={{
                          backgroundColor: getCurrentStatus(p.active, ''),
                          textAlign: 'center',
                        }}
                      ></Grid>
                      <Grid item xs={1}></Grid>
                      <Grid
                        item
                        xs={10}
                        style={{
                          textAlign: 'left',
                          paddingTop: 10,
                          paddingBottom: 10,
                        }}
                      >
                        <Grid container>
                          <Grid item xs={12} style={{ textAlign: 'left' }}>
                            <span
                              style={{
                                fontSize: 20,
                                color: '#004f9f',
                                fontWeight: 'bolder',
                              }}
                            >
                              {p.process_id}
                            </span>
                          </Grid>
                          <Grid item xs={12} style={{ textAlign: 'left' }}>
                            <span style={{ fontSize: 14 }}>
                              Status: {getCurrentStatus(p.active, 'progress')}
                            </span>
                          </Grid>
                          <Grid item xs={12} style={{ textAlign: 'left' }}>
                            <span style={{ fontSize: 14 }}>
                              KundenNr.: {p.albw_customerNumber}
                            </span>
                          </Grid>
                          <Grid item xs={12} style={{ textAlign: 'left' }}>
                            <span style={{ fontSize: 14 }}>
                              Kunde.: {p.companyName}
                            </span>
                          </Grid>
                          <Grid item xs={12} style={{ textAlign: 'left' }}>
                            <span style={{ fontSize: 14 }}>
                              Kontakt: {p.contactName}
                            </span>
                          </Grid>
                          <Grid item xs={12} style={{ textAlign: 'left' }}>
                            <span style={{ fontSize: 14 }}>
                              Auftr.Nr.:
                              <ul>
                                {p.assNumbers.map((ass, idx) => (
                                  <li key={idx}>{ass.assignmentNumber}</li>
                                ))}
                              </ul>
                            </span>
                          </Grid>
                          <Grid item xs={12} style={{ textAlign: 'left' }}>
                            <span style={{ fontSize: 14 }}>
                              Bem.:{' '}
                              <em>
                                {p.comment ? p.comment : 'kein Kommentar'}
                              </em>
                            </span>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              );
            })}
          </div>
        </>
      )}
    </>
  );
}
