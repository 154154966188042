import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import GroupAddIcon from "@material-ui/icons/GroupAdd";
import StoreIcon from "@material-ui/icons/Store";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import InboxIcon from "@material-ui/icons/Inbox";
import { useLocation } from "@reach/router";
import { Paper, Tab, Tabs } from "@material-ui/core";
import { navigate } from "@reach/router";

const useStyles = makeStyles((theme) => ({
  appBar: {
    top: "auto",
    bottom: 0,
    backgroundColor: "theme.palette.contrastThreshold",
    position: "fixed",
    width: "100%",
  },
  icon: { margin: "10px 0" },
  tabs: {
    "& .MuiTabs-indicator": {
      backgroundColor: "#00699a",
    },
    "& .MuiTab-textColorPrimary.Mui-selected": {
      color: "#00699a",
    },
  },
}));

function TabBar() {
  const [value, setValue] = React.useState();

  const handleChange = (event, newValue) => {
    setValue(newValue);
    switch (newValue) {
      case 0:
        navigate("/");
        break;
      case 1:
        navigate("/returns");
        break;
      case 2:
        navigate("/repairs");
        break;
      case 3:
        navigate("/profile");
        break;
      default:
        navigate("/");
        break;
    }
  };

  const location = useLocation();
  const classes = useStyles();
  let page;

  if (location.pathname.match("/")) {
    page = 0;
  }
  if (location.pathname.match("/returns")) {
    page = 1;
  }
  if (location.pathname.match("/repairs")) {
    page = 2;
  }
  if (location.pathname.match("/profile")) {
    page = 3;
  }

  return (
    <Paper square className={classes.appBar}>
      <Tabs
        value={value ?? page}
        onChange={handleChange}
        variant="fullWidth"
        indicatorColor="primary"
        textColor="primary"
        aria-label="navbar"
        className={classes.tabs}
      >
        <Tab
          icon={<GroupAddIcon size="24px" className={classes.icon} />}
          label="Kunden"
        />
        <Tab
          icon={<StoreIcon size="24px" className={classes.icon} />}
          label="Rückg."
        />
        <Tab
          icon={<InboxIcon size="24px" className={classes.icon} />}
          label="Rep."
        />
        <Tab
          icon={<AccountCircleIcon size="24px" className={classes.icon} />}
          label="Profil"
        />
      </Tabs>
    </Paper>
  );
}

export default TabBar;
