import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Grid from "@material-ui/core/Grid";
import Select from "@material-ui/core/Select";

import TextField from "@material-ui/core/TextField";
import CircularProgress from "@material-ui/core/CircularProgress";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

import validator from "validator";

import axios from "axios";
import CookieService from "../../services/CookieService";

export default function CreateReturn({
  open,
  closeModal,
  setOpen,
  fetchNew,
  assignment,
  loading,
  selectedPositions,
  updateSelection,
}) {
  const [arrCheckboxes, setArrCheckboxes] = useState([]);
  const [reason, setReason] = useState("01");
  const [errors, setErrors] = useState("");
  const [description, setDescription] = useState("");
  const [email, setEmail] = useState("");

  const [loadingSendingForm, setLoadingSendingForm] = useState("");

  const changeSelected = (value, idx) => {
    if (!isNaN(value)) {
      let arrSelected = selectedPositions;
      arrSelected[idx].ANZAHL_RUECKGABE = value;
      updateSelection(arrSelected);
    }
  };

  const toggleCheckbox = (idx) => {
    let arrCheck = arrCheckboxes;
    const result = arrCheck.filter((check) => check === idx);
    if (result.length === 0) {
      arrCheck.push(idx);
    } else {
      const result2 = arrCheck.filter((check) => check !== idx);
      arrCheck = result2;
    }
    setArrCheckboxes(arrCheck);
  };

  const validateForm = () => {
    setLoadingSendingForm(true);
    let err = {};
    let isValid = true;

    if (!validator.isEmail(email)) {
      err.email = "Bitte geben Sie eine gültige E-Mail-Adresse an";
      isValid = false;
    }

    if (!description) {
      err.description = "Bitte beschreiben Sie den Vorgang";
      isValid = false;
    }

    if (arrCheckboxes.length === 0) {
      err.positions = "Bitte treffen Sie eine Auswahl!";
      isValid = false;
    } else {
      arrCheckboxes.forEach(function (check) {
        selectedPositions.forEach(function (pos) {
          if (check === pos.LFD_NR) {
            if (pos.ANZAHL_RUECKGABE) {
              if (
                parseInt(pos.ANZAHL_RUECKGABE) <= 0 ||
                parseInt(pos.ANZAHL_RUECKGABE) > parseInt(pos.MENGE_MEVERKAUF)
              ) {
                console.log("CASE INSIDE");
                console.log("anz rg", typeof pos.ANZAHL_RUECKGABE);
                console.log("anz menge me vk", typeof pos.MENGE_MEVERKAUF);
                err.positions =
                  "Menge darf nicht KLEINER und GLEICH 0 und größer als die Auftragsmenge sein!";
                isValid = false;
              }
            }
            if ("ANZAHL_RUECKGABE" in pos && pos.MENGE_MEVERKAUF < 0) {
              err.positions =
                "Es dürfen keine Minuspositionen zurückgegeben werden!";
              isValid = false;
            }
            if ("ANZAHL_RUECKGABE" in pos && isNaN(pos.ANZAHL_RUECKGABE)) {
              err.positions = "Menge muss ein nummerischer Wert sein!";
              isValid = false;
            }

            if ("ANZAHL_RUECKGABE" in pos && pos.ANZAHL_RUECKGABE === "") {
              err.positions = "Menge muss ein nummerischer Wert sein!";
              isValid = false;
            }
          }
        });
      });
    }

    if (isValid) {
      setErrors([]);
      sendForm();
    } else {
      setLoadingSendingForm(false);
      setErrors(err);
    }
  };

  const sendForm = () => {
    const bAuth = "Bearer " + CookieService.get("access_token");
    const req = {
      selectedPositions: selectedPositions,
      arrCheckboxes: arrCheckboxes,
      reason: reason,
      description: description,
      email: email,
    };

    axios
      .post("/return/set/new/processe", req, {
        headers: { Authorization: bAuth },
      })
      .then((res) => {
        if (res.data.code !== 200) {
          alert(
            "Es ist etwas schief gelaufen, versuchen Sie es später erneut."
          );
          console.log(res.data);
          setLoadingSendingForm(false);
        } else {
          setLoadingSendingForm(false);
          setOpen(false);
          closeModal();
          fetchNew();
          alert("Anlage erfolgreich eingereicht.");
        }
      })
      .catch((err) => {
        console.log(err);
        setLoadingSendingForm(false);
        alert("Es ist etwas schief gelaufen, versuchen Sie es später erneut.");
      });
  };

  return (
    <Dialog
      style={{ maxWidth: "100vw" }}
      open={open}
      // onClose={handleClose}
      scroll={"paper"}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
    >
      <DialogTitle id="scroll-dialog-title">
        {assignment.AUFTRAGSNR}
      </DialogTitle>
      {/* <DialogContent dividers={scroll === 'paper'}> */}
      <DialogContent dividers={true}>
        <Grid container>
          <Grid item xs={12} style={{ textAlign: "left" }}>
            <h4 style={{ marginBottom: 5 }}>Grund der Rückgabe</h4>
            <small>Hier bitte die Begründung der Rückgabe angeben.</small>
            <Select
              native
              style={{ width: "100%", marginTop: 25 }}
              value={reason}
              onChange={(e) => setReason(e.target.value)}
              name="reason"
              inputProps={{
                id: "age-native-required",
              }}
            >
              <option value={"01"}>Warenrückgabe</option>
              <option value={"06"}>Reklamation</option>
              <option value={"10"}>Bestellung falsch</option>
              <option value={"11"}>Fehler ADM</option>
            </Select>
          </Grid>
          <Grid item xs={12} style={{ textAlign: "left", marginTop: 25 }}>
            <h4 style={{ marginBottom: 5 }}>Beschreibung</h4>
            <small>Hier die Beschreibung Ihrer Rücksendung eingeben.</small>
            <TextField
              required
              type="text"
              label="Beschreibung"
              style={{ marginTop: 10 }}
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              fullWidth
            />
            <span style={{ color: "red" }}>{errors.description}</span>
          </Grid>

          <Grid item xs={12} style={{ textAlign: "left", marginTop: 25 }}>
            <h4 style={{ marginBottom: 5 }}>E-Mail</h4>
            <small>
              Bekommt Bestätigungsmail und Beilegzettel. | Bei Warenabgabe
              eigene E-Mail eingeben.
            </small>
            <TextField
              required
              type="email"
              label="E-Mail"
              style={{ marginTop: 10 }}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              fullWidth
            />
            <span style={{ color: "red" }}>{errors.email}</span>
          </Grid>
          <Grid item xs={12} style={{ textAlign: "left", marginTop: 25 }}>
            <h4 style={{ marginBottom: 5 }}>Positionen</h4>

            <small>Welche Positionen werden zurückgegeben?</small>
            <br></br>
            <span style={{ color: "red" }}>{errors.positions}</span>
            {loading ? (
              <>
                <div style={{ textAlign: "center", marginTop: "5vh" }}>
                  <CircularProgress />
                </div>
              </>
            ) : (
              <>
                <br />
                {selectedPositions.map((s, idx) => {
                  return (
                    <Grid
                      item
                      xs={12}
                      style={{ textAlign: "left", marginTop: 25 }}
                      key={s.LFD_NR}
                    >
                      <FormControlLabel
                        control={
                          <Checkbox
                            // checked={state.checkedB}
                            // onChange={handleChange}
                            name="checkedB"
                            color="primary"
                            onClick={() => toggleCheckbox(s.LFD_NR)}
                          />
                        }
                        label={
                          <div style={{ fontSize: 12 }}>
                            Pos.: {s.AUFTRAGSPOS}
                            <br />
                            Art.nr.: {s.ARTIKELNR_EDV}
                            <br />
                            Bez.1: <b>{s.BEZEICHNUNG_1}</b>
                            <br />
                            Bez.2: {s.BEZEICHNUNG_2}
                            <br />
                            Bez.3: {s.BEZEICHNUNG_3}
                            <br />
                            Anzahl: <br />
                            <TextField
                              id="outlined-number"
                              // label="Anzahl"
                              type="number"
                              style={{
                                width: 100,
                              }}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              inputProps={{
                                inputMode: "numeric",
                                min: 0,
                                max: s.MENGE_MEVERKAUF,
                                pattern: "[0-9]*",
                              }}
                              // variant="outlined"
                              size="small"
                              defaultValue={s.MENGE_MEVERKAUF}
                              onChange={(e) =>
                                changeSelected(e.target.value, idx)
                              }
                            />{" "}
                            /{s.MENGE_MEVERKAUF} {s.MEVERKAUF}
                            <br />
                          </div>
                        }
                      />
                    </Grid>
                  );
                })}
              </>
            )}
            {/* <span style={{ color: "red" }}>{errors.companyTelephone}</span> */}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        {!loadingSendingForm ? (
          <>
            <Button color="primary" onClick={() => setOpen(false)}>
              zurück
            </Button>
            <Button color="primary" onClick={() => validateForm()}>
              abschicken
            </Button>
          </>
        ) : (
          <>
            <Button disabled color="primary" onClick={() => setOpen(false)}>
              zurück
            </Button>
            <Button disabled color="primary" onClick={() => validateForm()}>
              bitte warten
            </Button>
          </>
        )}
      </DialogActions>
    </Dialog>
  );
}
