import React from "react";
import Grid from "@material-ui/core/Grid";

import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";
import LoginForm from "./LoginForm";
import Logo from "../assets/img/logo.svg";



function Copyright() {

  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href="https://www.albw.de/">
        albw Handels GmbH,
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
  },
  layout: {
    width: "auto",
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
      width: 600,
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
  },
  stepper: {
    padding: theme.spacing(3, 0, 5),
  },
  buttons: {
    display: "flex",
    justifyContent: "flex-end",
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
}));

export default function LoginScreen() {
  const classes = useStyles();

  return (
    <React.Fragment>
      <CssBaseline />
      <AppBar
        position="absolute"
        color="default"
        className={classes.appBar}
        style={{ paddingTop: 10, paddingBottom: 10 }}
      >
        <Toolbar className={classes.layout}>
          <img alt="Logo" src={Logo} style={{ width: 110 }}></img>

        </Toolbar>
      </AppBar>
      <main className={classes.layout}>
        <React.Fragment>
          <React.Fragment>
            <LoginForm />
          </React.Fragment>
        </React.Fragment>
      </main>
      {/* Footer */}
      <footer
        className={classes.footer}
        style={{ marginTop: 30, marginBottom: 30 }}
      >
        <Grid item xs={12} style={{ marginTop: 15, textAlign: "center" }}>
          <Link href="https://www.albw.de/impressum.html">Impressum</Link> |{" "}
          <Link href="https://www.albw.de/datenschutzerklaerung.html">Datenschutz</Link>
        </Grid>
        <Typography
          variant="subtitle1"
          align="center"
          color="textSecondary"
          component="p"
        ></Typography>
        <Copyright />
      </footer>
      {/* End footer */}
    </React.Fragment>
  );
}
