import React, {  useEffect } from "react";

import CookieService from "../../services/CookieService";
import CheckAuthService from "../../services/CheckAuthService";

import NeukundenFormBusiness from "./NeukundenFormBusiness";
import NeukundenFormPrivate from "./NeukundenFormPrivate";







export default function NeukundenForm({ type }) {


  useEffect(() => {
    const accessToken = CookieService.get("access_token");
    if (!accessToken) {
      window.location.replace("/");
    } else {
      CheckAuthService.check();
    }
  }, []);


  const displayForm = () => {
    if (type === "private") {
      return <NeukundenFormPrivate />
    } else if (type === "business") {
      return <NeukundenFormBusiness />
    } else {
      window.location.replace("/");

    }
  }



  return (
    <>
        <>
          {displayForm()}
        </>
    </>
  );
}
