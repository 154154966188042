import React, { useState, useEffect } from "react";

import Grid from "@material-ui/core/Grid";
import CircularProgress from "@material-ui/core/CircularProgress";


import CookieService from "../services/CookieService";
import CheckAuthService from "../services/CheckAuthService";
import NewReturnModal from "./retouren/NewReturnModal";

import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";

import axios from "axios";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import CommentModalReturn from "./retouren/CommentModalReturn";

import AddCircleIcon from '@material-ui/icons/AddCircle';


const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  paper: {
    width: "90%",
    height: "30%",
    backgroundColor: theme.palette.background.paper,
    borderRadius: 5,
  },
  paperPositive: {
    width: "90%",
    height: "80%",
    backgroundColor: theme.palette.background.paper,
    borderRadius: 5,
  },
  root: {
    display: "flex",
  },
  clickArea: {
    display: "flex",
  },
  content: {
    flex: 1,
  },
  cover: {
    maxHeight: 100,
    height: "80%",
    width: "30%",
    marginRight: "3%",
  },
  description: {
    minHeight: 40,
    paddingBottom: "5px",
  },
}));



export default function Retouren({ navigate }) {
  const classes = useStyles();
  const theme = useTheme();
  const [comment, setComment] = useState("");

  const [loading, setLoading] = useState(false);


  const [arrProcesses, setArrProcesses] = useState([]);
  const [clickedModal, setClickedModal] = useState({});

  const [modalReturnComment, setModalReturnComment] = useState(false);
  const [modalNewReturn, setModalNewReturn] = useState(false);





  useEffect(() => {
    setLoading(true);
    const accessToken = CookieService.get("access_token");
    if (!accessToken) {
      window.location.replace("/");
    } else {
      CheckAuthService.check();
      getData();
    }
  }, []);

  const getData = () => {
    setLoading(true);
    const bAuth = "Bearer " + CookieService.get("access_token");
    axios
      .get("/return/get/returns", {
        headers: { Authorization: bAuth },
      })
      .then((res) => {
        if (res.data.code !== 200) {
          alert(res.data.message);
          setLoading(false);
        } else {
          setArrProcesses(res.data.allProcesses);
          setLoading(false);
        }
      })
      .catch((err) => {
        //GEHT NICHT HANDLING!
      });
  };


  const cardStyles = () => {
    return { margin: theme.spacing(2) };
  };

  const getCurrentStatus = (status, type) => {
    if (type === "progress") {
      if (status === "active") {
        return "in Bearbeitung";
      } else {
        return "erledigt";
      }
    } else {
      if (status === "active") {
        return "orange";
      } else {
        return "green";
      }
    }
  }

  const closeModalComment = () => {
    setModalReturnComment(false);
  }

  const closeModalType = () => {
    setModalNewReturn(false);
  }

  const onClickCard = (model) => {
    setComment(model.comment);
    setModalReturnComment(true);
    setClickedModal(model);
  }


  return (
    <>
      {loading ? (
        <div style={{ textAlign: "center", marginTop: "45vh" }}>
          <CircularProgress />
        </div>
      ) : (
        <>
          <div
            style={{
              position: "fixed",
              right: "20px",
              bottom: "80px",
              height: "75px",
              width: "75px",
            }}
            onClick={() => setModalNewReturn(true)}
          >
            <AddCircleIcon style={{ color: "#004f9f", fontSize: 75, fontWeight: "bold" }} />
          </div>
          <CommentModalReturn
            openModal={modalReturnComment}
            closeModal={() => closeModalComment.bind(this)}
            closeMod={closeModalComment.bind(this)}
            model={clickedModal}
            comment={comment}
            setComment={setComment.bind(this)}
            cookie={CookieService.get("access_token")}
            fetchData={getData.bind(this)}
          />

          <NewReturnModal
            openModal={modalNewReturn}
            closeModal={() => closeModalType.bind(this)}
            navigate={navigate}
            fetchData={getData.bind(this)}
          />



          <div style={{ marginBottom: 80 }}>
            {arrProcesses.map((p) => {
              return (
                <Card
                  key={p.id}
                  elevation={5}
                  style={cardStyles()}
                  className={classes.root}
                  onClick={() => onClickCard(p)}
                >
                  <CardContent className={classes.content} style={{ padding: 0, }}>
                    <Grid container >
                      <Grid item xs={1} style={{ backgroundColor: getCurrentStatus(p.status, ""), textAlign: "center" }} >
                      </Grid>
                      <Grid item xs={1}>
                      </Grid>
                      <Grid item xs={10} style={{ textAlign: "left", paddingTop: 10, paddingBottom: 10, }} >
                        <Grid container >
                          <Grid item xs={12} style={{ textAlign: "left" }} >
                            <span style={{ fontSize: 20, color: "#004f9f", fontWeight: "bolder" }}>{p.process_id}</span>
                          </Grid>
                          <Grid item xs={12} style={{ textAlign: "left" }} >
                            <span style={{ fontSize: 14 }}>Status: {getCurrentStatus(p.status, "progress")}</span>
                          </Grid>
                          <Grid item xs={12} style={{ textAlign: "left" }} >
                            <span style={{ fontSize: 14 }}>Kunde: {p.shippingName1}</span>
                          </Grid>
                          <Grid item xs={12} style={{ textAlign: "left" }} >
                            <span style={{ fontSize: 14 }}>IDM: {p.host}</span>
                          </Grid>
                          <Grid item xs={12} style={{ textAlign: "left" }} >
                            <span style={{ fontSize: 14 }}>Bem.: <em>{p.comment ? p.comment : "kein Kommentar"}</em></span>
                          </Grid>

                        </Grid>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              );
            })}
          </div>
        </>
      )
      }
    </>
  );
}
