import React, { useState } from "react";
import { Modal } from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import TextField from "@material-ui/core/TextField";
import axios from "axios";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { isTablet } from "react-device-detect";
import CookieService from "../../services/CookieService";

import CircularProgress from "@material-ui/core/CircularProgress";
import CreateRepair from "./CreateRepair";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  paper: {
    width: "90%",
    height: "30%",
    backgroundColor: theme.palette.background.paper,
    borderRadius: 5,
  },
  paperPositive: {
    width: "90%",
    height: "90%",
    backgroundColor: theme.palette.background.paper,
    borderRadius: 5,
  },
  root: {
    display: "flex",
  },
  clickArea: {
    display: "flex",
  },
  content: {
    flex: 1,
  },
  cover: {
    maxHeight: 100,
    height: "80%",
    width: "30%",
    marginRight: "3%",
  },
  description: {
    minHeight: 40,
    paddingBottom: "5px",
  },
}));

function NewRepairModal({ openModal, closeModal, comment, fetchData }) {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [noResult, setNoResult] = useState(true);
  const [arrCustomers, setArrCustomers] = useState([]);

  const [currentCustomer, setCurrentCustomer] = useState({});
  const [selectionClicked, setSelectionClicked] = useState(false);

  const theme = useTheme();
  const cardStyles = () => {
    return {
      margin: theme.spacing(2),
      paddingLeft: 20,
    };
  };

  const onInputSearch = (value) => {
    setLoading(true);
    fetchAssignments(value);
  };

  const setCustomer = (value) => {
    setCurrentCustomer(value);

    // closeModal();
    setSelectionClicked(true);
  };

  const fetchAssignments = (search) => {
    const bAuth = "Bearer " + CookieService.get("access_token");

    axios
      .get("/repairs/search/customers/" + search, {
        headers: { Authorization: bAuth },
      })
      .then((res) => {
        if (res.data.code !== 200) {
          setNoResult(true);
          setLoading(false);
        } else {
          setArrCustomers(res.data.customers);
          setNoResult(false);
          setLoading(false);
        }
      })
      .catch((err) => {
        setNoResult(true);
        setLoading(false);
      });
  };

  return (
    <>
      <CreateRepair
        open={selectionClicked}
        setOpen={setSelectionClicked}
        customer={currentCustomer}
        setCustomer={(a) => setCurrentCustomer(a)}
      />

      <Modal
        open={openModal}
        onClose={closeModal()}
        aria-labelledby="Modal für neuen Auftrag"
        aria-describedby="Suche und erzeuge eine neue Retoure"
        className={classes.modal}
      >
        <div className={classes.paperPositive} style={{ paddingTop: 5 }}>
          <div style={{ textAlign: "right", marginRight: 5 }}>
            <CloseIcon
              className={classes.icon}
              onClick={closeModal()}
              style={{ paddingLeft: "45px" }}
            />
          </div>

          <div
            style={{
              textAlign: "center",
              fontSize: 14,
              paddingLeft: 10,
              paddingRight: 10,
            }}
          >
            Bitte geben Sie Kundennummer oder Name ein <br />
            <br />
          </div>

          <div style={{ marginTop: 0, paddingLeft: 20, paddingRight: 20 }}>
            <TextField
              label="Kundennummer / Name"
              value={comment}
              onChange={(e) => onInputSearch(e.target.value)}
              fullWidth
            />
          </div>

          <hr style={{ marginTop: 15, color: "#004f9f" }}></hr>
          {loading ? (
            <div style={{ textAlign: "center", marginTop: "10vh" }}>
              <CircularProgress />
            </div>
          ) : (
            <>
              {noResult ? (
                <div style={{ textAlign: "center", marginTop: "10vh" }}>
                  Kein Suchergebnis
                </div>
              ) : (
                <>
                  {arrCustomers.map((a) => {
                    return (
                      <Card
                        key={a.id}
                        elevation={5}
                        style={cardStyles()}
                        className={classes.root}
                        onClick={() => setCustomer(a)}
                      >
                        <CardContent
                          className={classes.content}
                          style={{ padding: 0 }}
                        >
                          <Grid container>
                            <Grid
                              item
                              xs={10}
                              style={{
                                textAlign: "left",
                                paddingTop: 10,
                                paddingBottom: 10,
                              }}
                            >
                              <Grid container>
                                <Grid
                                  item
                                  xs={12}
                                  style={{ textAlign: "left" }}
                                >
                                  <span
                                    style={{
                                      fontSize: 14,
                                      color: "#004f9f",
                                      fontWeight: "bolder",
                                    }}
                                  >
                                    {a.name1}
                                  </span>
                                </Grid>
                                <Grid container>
                                  {!isTablet ? (
                                    <Grid
                                      item
                                      xs={12}
                                      style={{ textAlign: "left" }}
                                    >
                                      <span style={{ fontSize: 10 }}>
                                        Kundennr: {a.customerNumber}
                                      </span>{" "}
                                      <br />
                                      <span style={{ fontSize: 10 }}>
                                        Name2: {a.name2}
                                      </span>{" "}
                                      <br />
                                      <span style={{ fontSize: 10 }}>
                                        PLZ: {a.postalcode}{" "}
                                      </span>{" "}
                                      <br />
                                      <span style={{ fontSize: 10 }}>
                                        Stadt: {a.city}
                                      </span>
                                    </Grid>
                                  ) : (
                                    <>
                                      <Grid
                                        item
                                        xs={12}
                                        style={{ textAlign: "left" }}
                                      >
                                        <span style={{ fontSize: 12 }}>
                                          Kundennr: {a.customerNumber}
                                        </span>{" "}
                                        <br />
                                        <span style={{ fontSize: 12 }}>
                                          Name2: {a.name2}
                                        </span>{" "}
                                        <br />
                                        <span style={{ fontSize: 12 }}>
                                          PLZ: {a.postalcode}{" "}
                                        </span>{" "}
                                        <br />
                                        <span style={{ fontSize: 12 }}>
                                          Stadt: {a.city}
                                        </span>
                                      </Grid>
                                      <Grid
                                        item
                                        xs={8}
                                        style={{ textAlign: "left" }}
                                      >
                                        <span style={{ fontSize: 12 }}>
                                          Name2: {a.name2}
                                        </span>{" "}
                                        <br />
                                        <span style={{ fontSize: 12 }}>
                                          Straße: {a.street}
                                        </span>{" "}
                                        <br />
                                        <span style={{ fontSize: 12 }}>
                                          Email: {a.email}
                                        </span>
                                      </Grid>
                                    </>
                                  )}
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid
                              item
                              xs={2}
                              style={{ marginTop: "2em", paddingLeft: "1em" }}
                            >
                              <span style={{ fontSize: 30 }}>&gt;</span>
                            </Grid>
                          </Grid>
                        </CardContent>
                      </Card>
                    );
                  })}
                </>
              )}
            </>
          )}
        </div>
      </Modal>
    </>
  );
}

export default NewRepairModal;
