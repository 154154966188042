import axios from "axios";
import CookieService from "./CookieService";

class CheckAuthService {
  check() {
    const bearer = CookieService.get("access_token");
    const bAuth = "Bearer " + bearer;
    axios
      .get("/user/is/online", {
        headers: { Authorization: bAuth },
      })
      .then((res) => {
        if (res.data.code !== 200) {
          CookieService.remove("access_token");
          window.location.replace("/");
        }
      })
      .catch((err) => {
        console.log(err.message);
        if (err.message === "Request failed with status code 401") {
          CookieService.remove("access_token");
          window.location.replace("/");
        }
      });
  }
}

export default new CheckAuthService();
