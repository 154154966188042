import React, { useState } from "react";
import Button from "@mui/material/Button";
import Grid from "@material-ui/core/Grid";
import Select from "@material-ui/core/Select";

import TextField from "@material-ui/core/TextField";

export default function CreateRepairPageTwo({
  backStep,
  repairProducts,
  setRepairProducts,
  sendFormular,
}) {
  const [serialNumber, setSerialNumber] = useState("");
  const [deviceDescription, setDeviceDescription] = useState("");
  const [errorDescription, setErrorDescription] = useState("");
  const [repairType, setRepairType] = useState("Garantiereparatur");
  const [count, setCount] = useState(1);
  const [trigger, setTrigger] = useState(0);
  const [errors, setErrors] = useState({});

  const sendFormNew = () => {
    let err = {};
    let isValid = true;
    repairProducts.length === 0 &&
      (err.positions = "Bitte fügen Sie Produkt hinzu.");

    Object.keys(err).length !== 0 && (isValid = false);

    isValid ? sendFormular() : setErrors(err);
  };

  const addProduct = () => {
    let err = {};
    let isValid = true;

    serialNumber === "" &&
      (err.serialNumber = "Bitte tragen Sie die Seriennr. ein.");
    deviceDescription === "" &&
      (err.deviceDescription = "Bitte tragen Sie die Beschreibung ein.");
    errorDescription === "" &&
      (err.errorDescription = "Bitte tragen Sie den Fehler ein.");
    repairType === "" && (err.repairType = "Bitte tragen Sie den Typ ein.");
    count === "" && (err.count = "Bitte tragen Sie die Anzahl ein.");

    Object.keys(err).length !== 0 && (isValid = false);

    if (isValid) {
      setErrors([]);
      var products = repairProducts;
      products.push({
        serialNumber,
        deviceDescription,
        errorDescription,
        repairType,
        count,
      });
      setRepairProducts(products);
      setSerialNumber("");
      setDeviceDescription("");
      setErrorDescription("");
      setRepairType("Garantiereparatur");
      setCount(1);
      setTrigger(trigger + 1);
    } else {
      setErrors(err);
    }
  };

  return (
    <Grid container>
      <Grid item xs={12} style={{ textAlign: "left" }}>
        <h4>Deine Positionen</h4>
      </Grid>
      <Grid
        item
        xs={12}
        style={{
          backgroundColor: "rgb(190,190,190)",
          textAlign: "left",
          marginTop: 10,
        }}
      >
        <ul>
          {repairProducts.length > 0 ? (
            repairProducts.map((product, idx) => {
              return <li key={idx}>{product.deviceDescription}</li>;
            })
          ) : (
            <i>keine Position hinzugefügt.</i>
          )}
        </ul>
      </Grid>
      <Grid item xs={12} style={{ textAlign: "left", marginTop: 10 }}>
        <TextField
          required
          type="text"
          label="Seriennummer"
          style={{ marginTop: 10 }}
          value={serialNumber}
          onChange={(e) => setSerialNumber(e.target.value)}
          fullWidth
        />
        <span style={{ color: "red" }}>{errors.serialNumber}</span>
      </Grid>

      <Grid item xs={12} style={{ textAlign: "left", marginTop: 10 }}>
        <TextField
          required
          type="text"
          label="Gerätebezeichnung"
          style={{ marginTop: 10 }}
          value={deviceDescription}
          onChange={(e) => setDeviceDescription(e.target.value)}
          fullWidth
        />
        <span style={{ color: "red" }}>{errors.deviceDescription}</span>
      </Grid>

      <Grid item xs={12} style={{ textAlign: "left", marginTop: 10 }}>
        <TextField
          required
          type="number"
          label="Anzahl"
          style={{ marginTop: 10 }}
          value={count}
          onChange={(e) => setCount(e.target.value)}
          fullWidth
        />
        <span style={{ color: "red" }}>{errors.count}</span>
      </Grid>

      <Grid item xs={12} style={{ textAlign: "left", marginTop: 10 }}>
        <TextField
          required
          type="text"
          label="Beschr. Fehler + Zubehör"
          style={{ marginTop: 10 }}
          value={errorDescription}
          onChange={(e) => setErrorDescription(e.target.value)}
          fullWidth
        />
        <span style={{ color: "red" }}>{errors.errorDescription}</span>
      </Grid>

      <Select
        native
        style={{ width: "100%", marginTop: 25 }}
        value={repairType}
        onChange={(e) => setRepairType(e.target.value)}
        name="repairType"
        inputProps={{
          id: "age-native-required",
        }}
      >
        <option value={"Garantiereparatur"}>Garantiereparatur</option>
        <option value={"Kostenvoranschlag"}>Kostenvoranschlag</option>
        <option value={"Sofortreparatur"}>Sofortreparatur</option>
        <option value={"Schaerfen/Schleifen"}>Schaerfen/Schleifen</option>
      </Select>

      <Grid item xs={12} style={{ textAlign: "center", marginTop: 20 }}>
        <Button variant="outlined" onClick={() => addProduct()} color="success">
          hinzufügen
        </Button>
      </Grid>

      <Grid item xs={12} style={{ textAlign: "right", marginTop: 30 }}>
        <table style={{ width: "100%" }}>
          <tbody>
            <tr>
              <td style={{ width: "50%", textAlign: "left" }}>
                <Button
                  variant="outlined"
                  onClick={() => backStep()}
                  color="warning"
                >
                  zurück
                </Button>
              </td>
              <td style={{ width: "50%", textAlign: "right" }}>
                <Button
                  variant="contained"
                  onClick={() => sendFormNew()}
                  color="success"
                >
                  senden
                </Button>
              </td>
            </tr>
          </tbody>
        </table>
        <span style={{ color: "red" }}>{errors.positions}</span>
      </Grid>
    </Grid>
  );
}
