import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import CircularProgress from "@material-ui/core/CircularProgress";
import TextField from "@material-ui/core/TextField";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";

import CookieService from "../../services/CookieService";
import CheckAuthService from "../../services/CheckAuthService";

import axios from "axios";
import { makeStyles } from "@material-ui/core/styles";

import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormLabel from "@material-ui/core/FormLabel";
import { Checkbox, FormGroup } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  col: {
    padding: 10,
    marginTop: 20,
  },
}));

export default function NeukundenFormBusiness({ type }) {
  const classes = useStyles();

  const [loading, setLoading] = useState(false);
  const [loadingSendingForm, setLoadingSendingForm] = useState(false);
  const [errors, setErrors] = useState("");
  const [currentUser, setCurrentUser] = useState("");

  const [companyName, setCompanyName] = useState("");
  const [companyStreet, setCompanyStreet] = useState("");
  const [companyPostalcode, setCompanyPostalcode] = useState("");
  const [companyCity, setCompanyCity] = useState("");
  const [companyTelephone, setCompanyTelephone] = useState("");
  const [companyMobile, setCompanyMobile] = useState("");
  const [companyFax, setCompanyFax] = useState("");
  const [companyNumberOfEmployees, setCompanyNumberOfEmployees] = useState("");
  const [companyUstId, setCompanyUstId] = useState("");
  const [companyBranche, setCompanyBranche] = useState("Bitte auswählen!");
  const [companyConzern, setCompanyConzern] = useState("");
  const [companyDiscountGroup, setCompanyDiscountGroup] =
    useState("Bitte auswählen!");
  const [
    companyEmailGelangensbestaetigung,
    setCompanyEmailGelangensbestaetigung,
  ] = useState("");
  const [companyHomepage, setCompanyHomepage] = useState("");
  const [companyEmail, setCompanyEmail] = useState("");
  const [companyShopWanted, setCompanyShopWanted] = useState("nein");
  const [companyPaymentCondition, setCompanyPaymentCondition] =
    useState("Bitte auswählen!"); //CUstom kommt dazu wenn Sonstiges lul
  const [companyCustomPaymentCondition, setCompanyCustomPaymentCondition] =
    useState(""); // das ist custom!!
  const [companyWishedSepa, setCompanyWishedSepa] = useState("nein");
  const [companyBankAccount, setCompanyBankAccount] = useState("");
  const [companyBankBIC, setcompanyBankBIC] = useState("");
  const [companyBankIBAN, setCompanyBankIBAN] = useState("");
  const [otherDeliveryAdress, setOtherDeliveryAdress] = useState("nein");
  const [otherDeliveryAdressName, setOtherDeliveryAdressName] = useState("");
  const [otherDeliveryAdressStreet, setOtherDeliveryAdressStreet] =
    useState("");
  const [otherDeliveryAdressPostalcode, setOtherDeliveryAdressPostalcode] =
    useState("");
  const [otherDeliveryAdressCity, setOtherDeliveryAdressCity] = useState("");
  const [contactSex, setContactSex] = useState("Herr");
  const [contactName, setContactName] = useState("");
  const [contactPrename, setContactPrename] = useState("");
  const [contactTelefon, setContactTelefon] = useState("");
  const [contactFax, setContactFax] = useState("");
  const [contactEmail, setContactEmail] = useState("");
  const [contactDepartment, setContactDepartment] = useState("Allgemein");
  const [contactFunction, setContactFunction] = useState("Abteilungsleiter");
  const [customerIDM1, setCustomerIDM1] = useState("keine Angabe");
  const [customerIDM2, setCustomerIDM2] = useState("keine Angabe");
  const [invoiceEmail, setInvoiceEmail] = useState("");
  const [infoEmail, setInfoEmail] = useState([]);
  const [interestWare, setInterestWare] = useState([]);
  const [companyWishedAds, setCompanyWishedAds] = useState("nein");

  const [users, setUsers] = useState([]);

  useEffect(() => {
    setLoading(true);
    const accessToken = CookieService.get("access_token");
    if (!accessToken) {
      window.location.replace("/");
    } else {
      CheckAuthService.check();
      getAuth();
      getUsers();
    }
  }, []);

  async function setCompanyWishedAdsConcl(value) {
    setCompanyWishedAds(value);
    setInfoEmail([]);
    setInterestWare([]);
  }

  async function addInterestToArrayEmail(value) {
    let arrEmail = infoEmail;
    if (!value.checked) {
      for (var i = 0; i < arrEmail.length; i++) {
        if (arrEmail[i] === value.value) {
          arrEmail.splice(i, 1);
          i--;
        }
      }
    } else {
      arrEmail.push(value.value);
    }
    setInfoEmail(arrEmail);

    console.log(infoEmail.join(", "));
    // console.log(infoEmail);
  }

  async function addInterestToArrayWare(value) {
    let arrWare = interestWare;
    if (!value.checked) {
      for (var i = 0; i < arrWare.length; i++) {
        if (arrWare[i] === value.value) {
          arrWare.splice(i, 1);
          i--;
        }
      }
    } else {
      arrWare.push(value.value);
    }
    setInterestWare(arrWare);
    console.log(interestWare);
  }

  const getAuth = () => {
    const bAuth = "Bearer " + CookieService.get("access_token");
    axios
      .get("/user/auth/get", {
        headers: { Authorization: bAuth },
      })
      .then((res) => {
        if (res.data.code !== 200) {
          CookieService.remove("access_token");
          window.location.replace("/");
        } else {
          setCurrentUser(res.data.user);
          setLoading(false);
        }
      })
      .catch((err) => {
        //GEHT NICHT HANDLING!
      });
  };

  const getUsers = () => {
    const bAuth = "Bearer " + CookieService.get("access_token");
    axios
      .get("/user/get/user", {
        headers: { Authorization: bAuth },
      })
      .then((res) => {
        if (res.data.code !== 200) {
          CookieService.remove("access_token");
          window.location.replace("/");
        } else {
          setUsers(res.data.users);
        }
      })
      .catch((err) => {
        //GEHT NICHT HANDLING!
      });
  };

  const validateForm = () => {
    setLoadingSendingForm(true);
    let err = {};
    let isValid = true;

    if (!companyName) {
      err.companyName = "Firmenname muss ausgefüllt sein";
      isValid = false;
    }
    if (!companyStreet) {
      err.companyStreet = "Straße muss ausgefüllt sein";
      isValid = false;
    }
    if (!companyPostalcode) {
      err.companyPostalcode = "PLZ muss ausgefüllt sein";
      isValid = false;
    }
    if (!companyCity) {
      err.companyCity = "Stadt muss ausgefüllt sein";
      isValid = false;
    }
    if (!companyTelephone) {
      err.companyTelephone = "Telefon muss ausgefüllt sein";
      isValid = false;
    }
    if (!companyNumberOfEmployees) {
      err.companyNumberOfEmployees = "Anzahl muss ausgefüllt sein";
      isValid = false;
    }

    if (
      companyUstId &&
      !companyUstId.match(/([A-Za-z]{2}[0-9]{9})|([Aa][Tt][Uu][0-9]{8})/g)
    ) {
      err.companyUstId = "Bitte geben Sie eine valide ustid ein.";
      isValid = false;
    }

    if (!companyUstId) {
      err.companyUstId = "Ust.ID. muss ausgefüllt sein";
      isValid = false;
    }

    if (!companyEmail) {
      err.companyEmail = "E-Mail muss ausgefüllt sein";
      isValid = false;
    }

    if (companyPaymentCondition === "Bitte auswählen!") {
      err.companyPaymentCondition = "Bitte Auswahl tätigen!";
      isValid = false;
    }

    if (companyBranche === "Bitte auswählen!") {
      err.companyBranche = "Bitte Auswahl tätigen!";
      isValid = false;
    }

    if (companyPaymentCondition === "Sonstiges") {
      if (!companyCustomPaymentCondition) {
        err.companyCustomPaymentCondition =
          "Zahlungsmethode muss ausgefüllt werden";
        isValid = false;
      }
    }

    if (companyDiscountGroup === "Bitte auswählen!") {
      err.companyDiscountGroup = "Bitte Auswahl tätigen!";
      isValid = false;
    }

    if (companyWishedSepa === "ja") {
      if (!companyBankAccount) {
        err.companyBankAccount = "Name der Bank muss ausgefüllt sein";
        isValid = false;
      }
      if (!companyBankBIC) {
        err.companyBankBIC = "BIC muss ausgefüllt sein";
        isValid = false;
      }
      if (!companyBankIBAN) {
        err.companyBankIBAN = "IBAN muss ausgefüllt sein";
        isValid = false;
      }
    }
    if (otherDeliveryAdress === "ja") {
      if (!otherDeliveryAdressName) {
        err.otherDeliveryAdressName = "Adresse muss ausgefüllt sein";
        isValid = false;
      }
      if (!otherDeliveryAdressStreet) {
        err.otherDeliveryAdressStreet = "Straße muss ausgefüllt sein";
        isValid = false;
      }
      if (!otherDeliveryAdressPostalcode) {
        err.otherDeliveryAdressPostalcode = "PLZ muss ausgefüllt sein";
        isValid = false;
      }
      if (!otherDeliveryAdressCity) {
        err.otherDeliveryAdressCity = "Stadt muss ausgefüllt sein";
        isValid = false;
      }
    }
    if (!contactName) {
      err.contactName = "Nachname muss ausgefüllt sein";
      isValid = false;
    }
    if (!contactPrename) {
      err.contactPrename = "Vorname muss ausgefüllt sein";
      isValid = false;
    }
    if (!contactTelefon) {
      err.contactTelefon = "Telefonnr. muss ausgefüllt sein";
      isValid = false;
    }

    if (!contactEmail) {
      err.contactEmail = "Email muss ausgefüllt sein";
      isValid = false;
    }

    if (customerIDM1 === "keine Angabe") {
      err.customerIDM1 = "Wählen Sie den IDM aus";
      isValid = false;
    }

    if (companyWishedAds === "ja") {
      if (infoEmail.length === 0) {
        let arrMail = infoEmail;
        arrMail.push("keine Werbung via Email gewünscht");
        setInfoEmail(arrMail);
      }
      if (interestWare.length === 0) {
        let arrCat = interestWare;
        arrCat.push("keine Werbung via Kataloge gewünscht");
        setInterestWare(arrCat);
      }
    } else {
      let arrMail = infoEmail;
      let arrCat = interestWare;

      arrMail.push("keine Werbung via Email gewünscht");
      setInfoEmail(arrMail);
      arrCat.push("keine Werbung via Kataloge gewünscht");
      setInterestWare(arrCat);
    }

    if (isValid) {
      sendForm();
    } else {
      setLoadingSendingForm(false);
      setErrors(err);
    }
  };

  const sendForm = () => {
    const bAuth = "Bearer " + CookieService.get("access_token");
    const req = {
      companyName: companyName,
      companyStreet: companyStreet,
      companyPostalcode: companyPostalcode,
      companyCity: companyCity,
      companyTelephone: companyTelephone,
      companyMobile: companyMobile,
      companyFax: companyFax,
      companyNumberOfEmployees: companyNumberOfEmployees,
      companyUstId: companyUstId,
      companyBranche: companyBranche,
      companyConzern: companyConzern,
      companyDiscountGroup: companyDiscountGroup,
      companyEmailGelangensbestaetigung: companyEmailGelangensbestaetigung,
      companyHomepage: companyHomepage,
      companyEmail: companyEmail,
      companyShopWanted: companyShopWanted,
      companyPaymentCondition: companyPaymentCondition,
      companyCustomPaymentCondition: companyCustomPaymentCondition,
      companyWishedSepa: companyWishedSepa,
      companyBankAccount: companyBankAccount,
      companyBankBIC: companyBankBIC,
      companyBankIBAN: companyBankIBAN,
      otherDeliveryAdress: otherDeliveryAdress,
      otherDeliveryAdressName: otherDeliveryAdressName,
      otherDeliveryAdressStreet: otherDeliveryAdressStreet,
      otherDeliveryAdressPostalcode: otherDeliveryAdressPostalcode,
      otherDeliveryAdressCity: otherDeliveryAdressCity,
      contactSex: contactSex,
      contactName: contactName,
      contactPrename: contactPrename,
      contactTelefon: contactTelefon,
      contactFax: contactFax,
      contactEmail: contactEmail,
      contactDepartment: contactDepartment,
      contactFunction: contactFunction,
      customerIDM1: customerIDM1,
      customerIDM2: customerIDM2,
      customerADM: currentUser.prename + " " + currentUser.name,
      invoiceEmail: invoiceEmail,
      buyNameMarketing: "-",
      infoEmail: infoEmail.join(", "),
      interestWare: interestWare.join(", "),
    };
    axios
      .post("/customer/set/new/customer/business", req, {
        headers: { Authorization: bAuth },
      })
      .then((res) => {
        setInfoEmail([]);
        setInterestWare([]);
        if (res.data.code !== 200) {
          console.log(res.data.message);
          setLoadingSendingForm(false);
        } else {
          setLoadingSendingForm(false);
          alert("Anlage erfolgreich eingereicht.");
          window.location.replace("/");
        }
      })
      .catch((err) => {
        console.log(err);
        setLoadingSendingForm(false);
        alert("Es ist etwas schief gelaufen, versuchen Sie es später erneut.");
      });
  };

  return (
    <>
      {loading ? (
        <div style={{ textAlign: "center", marginTop: "45vh" }}>
          <CircularProgress />
        </div>
      ) : (
        <>
          <Grid container style={{ marginBottom: 100 }}>
            <Grid item xs={1}></Grid>
            <Grid item xs={10} container style={{ marginTop: "2vh" }}>
              <Grid item xs={12} className={classes.col}>
                <span style={{ fontSize: 24, color: "#004f9f" }}>
                  Neuer Firmenkunde
                </span>
              </Grid>
              <Grid item xs={12} className={classes.col}>
                <span style={{ fontSize: 18, color: "#000" }}>
                  Informationen Kunde
                </span>
              </Grid>
              <Grid item xs={12} sm={6} className={classes.col}>
                <TextField
                  required
                  type="text"
                  label="Firmenname"
                  value={companyName}
                  onChange={(e) => setCompanyName(e.target.value)}
                  fullWidth
                />
                <span style={{ color: "red" }}>{errors.companyName}</span>
              </Grid>
              <Grid item xs={12} sm={6} className={classes.col}>
                <TextField
                  required
                  type="text"
                  label="Firma Straße"
                  value={companyStreet}
                  onChange={(e) => setCompanyStreet(e.target.value)}
                  fullWidth
                />
                <span style={{ color: "red" }}>{errors.companyStreet}</span>
              </Grid>
              <Grid item xs={12} sm={6} className={classes.col}>
                <TextField
                  required
                  type="text"
                  label="Postleitzahl Firma"
                  value={companyPostalcode}
                  onChange={(e) => setCompanyPostalcode(e.target.value)}
                  fullWidth
                />
                <span style={{ color: "red" }}>{errors.companyPostalcode}</span>
              </Grid>
              <Grid item xs={12} sm={6} className={classes.col}>
                <TextField
                  required
                  type="text"
                  label="Stadt Firma"
                  value={companyCity}
                  onChange={(e) => setCompanyCity(e.target.value)}
                  fullWidth
                />
                <span style={{ color: "red" }}>{errors.companyCity}</span>
              </Grid>
              <Grid item xs={12} sm={6} className={classes.col}>
                <TextField
                  required
                  type="text"
                  label="Telefon Firma"
                  value={companyTelephone}
                  onChange={(e) => setCompanyTelephone(e.target.value)}
                  fullWidth
                />
                <span style={{ color: "red" }}>{errors.companyTelephone}</span>
              </Grid>
              <Grid item xs={12} sm={6} className={classes.col}>
                <TextField
                  type="text"
                  label="Handy Firma"
                  value={companyMobile}
                  onChange={(e) => setCompanyMobile(e.target.value)}
                  fullWidth
                />
                <span style={{ color: "red" }}>{errors.companyMobile}</span>
              </Grid>
              <Grid item xs={12} sm={6} className={classes.col}>
                <TextField
                  type="text"
                  label="Fax Firma"
                  value={companyFax}
                  onChange={(e) => setCompanyFax(e.target.value)}
                  fullWidth
                />
                <span style={{ color: "red" }}>{errors.companyFax}</span>
              </Grid>
              <Grid item xs={12} sm={6} className={classes.col}>
                <TextField
                  required
                  type="text"
                  label="Anzahl Mitarbeiter Firma"
                  value={companyNumberOfEmployees}
                  onChange={(e) => setCompanyNumberOfEmployees(e.target.value)}
                  fullWidth
                />
                <span style={{ color: "red" }}>
                  {errors.companyNumberOfEmployees}
                </span>
              </Grid>
              <Grid item xs={12} sm={6} className={classes.col}>
                <TextField
                  required
                  placeholder="DE123456789"
                  type="text"
                  label="Ust.ID Firma"
                  value={companyUstId}
                  onChange={(e) => setCompanyUstId(e.target.value)}
                  fullWidth
                />
                <span style={{ color: "red" }}>{errors.companyUstId}</span>
              </Grid>
              <Grid item xs={12} sm={6} className={classes.col}>
                <InputLabel htmlFor="age-native-required">
                  Branche Firma
                </InputLabel>
                <Select
                  native
                  style={{ width: "100%" }}
                  value={companyBranche}
                  onChange={(e) => setCompanyBranche(e.target.value)}
                  name="companyBranche"
                  inputProps={{
                    id: "age-native-required",
                  }}
                >
                  <option value={"Bitte auswählen!"}>Bitte auswählen!</option>
                  <option value={"Abbruchunternehmen"}>
                    Abbruchunternehmen
                  </option>
                  <option value={"Amt/Behörde/BW"}>Amt/Behörde/BW</option>
                  <option value={"Artus"}>Artus</option>
                  <option value={"Auto/KFZ"}>Auto/KFZ</option>
                  <option value={"Baustoffe"}>Baustoffe</option>
                  <option value={"Bauunternehmer"}>Bauunternehmer</option>
                  <option value={"Betonwerk"}>Betonwerk</option>
                  <option value={"Dachbau/Zimmerg."}>Dachbau/Zimmerg.</option>
                  <option value={"Diverse"}>Diverse</option>
                  <option value={"Eis"}>Eis</option>
                  <option value={"Elektro"}>Elektro</option>
                  <option value={"Estrichbau"}>Estrichbau</option>
                  <option value={"Fensterbau"}>Fensterbau</option>
                  <option value={"Firmenangehoerige"}>Firmenangehoerige</option>
                  <option value={"Fliesenleger"}>Fliesenleger</option>
                  <option value={"Gaststaetten"}>Gaststaetten</option>
                  <option value={"Garten/Landschaft"}>Garten/Landschaft</option>
                  <option value={"Gipser"}>Gipser</option>
                  <option value={"Handel"}>Handel</option>
                  <option value={"Hausverw./Serviceleist."}>
                    Hausverw./Serviceleist.
                  </option>
                  <option value={"Industrie"}>Industrie</option>
                  <option value={"Kieswerk"}>Kieswerk</option>
                  <option value={"Maler"}>Maler</option>
                  <option value={"Montagebau"}>Montagebau</option>
                  <option value={"Pflasterbau"}>Pflasterbau</option>
                  <option value={"Plus 6"}>Plus 6</option>
                  <option value={"Privat"}>Privat</option>
                  <option value={"Sanitaer/Heizung"}>Sanitaer/Heizung</option>
                  <option value={"Schornsteintechnik"}>
                    Schornsteintechnik
                  </option>
                  <option value={"Schreiner"}>Schreiner</option>
                  <option value={"Stadt/Gemeinde"}>Stadt/Gemeinde</option>
                  <option value={"Stahl/Metall"}>Stahl/Metall</option>
                  <option value={"Straßenbau"}>Straßenbau</option>
                  <option value={"Tankschutz"}>Tankschutz</option>
                  <option value={"Tiefbau"}>Tiefbau</option>
                  <option value={"Transporte"}>Transporte</option>
                  <option value={"Vereine"}>Vereine</option>
                  <option value={"Automobilhersteller"}>
                    Automobilhersteller
                  </option>
                  <option value={"Automobilzulieferer"}>
                    Automobilzulieferer
                  </option>
                  <option value={"Stromerzeugung"}>Stromerzeugung</option>
                  <option value={"Medizintechnik"}>Medizintechnik</option>
                  <option value={"Pharma"}>Pharma</option>
                  <option value={"Hygienewarenhersteller"}>
                    Hygienewarenhersteller
                  </option>
                  <option value={"Lebensmittelhersteller"}>
                    Lebensmittelhersteller
                  </option>
                </Select>
                <span style={{ color: "red" }}>{errors.companyBranche}</span>
              </Grid>
              <Grid item xs={12} sm={6} className={classes.col}>
                <TextField
                  type="text"
                  label="Konzern Firma"
                  value={companyConzern}
                  onChange={(e) => setCompanyConzern(e.target.value)}
                  fullWidth
                />
                <span style={{ color: "red" }}>{errors.companyConzern}</span>
              </Grid>
              <Grid item xs={12} sm={6} className={classes.col}>
                <InputLabel htmlFor="age-native-required">
                  Rabattgruppe Firma
                </InputLabel>
                <Select
                  native
                  style={{ width: "100%" }}
                  value={companyDiscountGroup}
                  onChange={(e) => setCompanyDiscountGroup(e.target.value)}
                  name="companyDiscountGroup"
                  inputProps={{
                    id: "age-native-required",
                  }}
                >
                  <option value={"Bitte auswählen!"}>Bitte auswählen!</option>
                  <option value={"10001 Industrie A Kunden"}>
                    10001 Industrie A Kunden
                  </option>
                  <option value={"10002 Industrie B Kunden"}>
                    10002 Industrie B Kunden
                  </option>
                  <option value={"10003 Handwerker A Kunden"}>
                    10003 Handwerker A Kunden
                  </option>
                  <option value={"10004 Handwerker B Kunden"}>
                    10004 Handwerker B Kunden
                  </option>
                  <option value={"10006 Privatkunden"}>
                    10006 Privatkunden
                  </option>
                  <option value={"10007 Wiederverkäufer"}>
                    10007 Wiederverkäufer
                  </option>
                  <option value={"10008 Lückenfüller"}>
                    10008 Lückenfüller
                  </option>
                </Select>
                <span style={{ color: "red" }}>
                  {errors.companyDiscountGroup}
                </span>
              </Grid>
              <Grid item xs={12} sm={6} className={classes.col}>
                <TextField
                  type="text"
                  label="E-Mail Gelangensbestätigung Firma"
                  value={companyEmailGelangensbestaetigung}
                  onChange={(e) =>
                    setCompanyEmailGelangensbestaetigung(e.target.value)
                  }
                  fullWidth
                />
                <span style={{ color: "red" }}>
                  {errors.companyEmailGelangensbestaetigung}
                </span>
              </Grid>
              <Grid item xs={12} sm={6} className={classes.col}>
                <TextField
                  type="text"
                  label="Homepage Firma"
                  value={companyHomepage}
                  onChange={(e) => setCompanyHomepage(e.target.value)}
                  fullWidth
                />
                <span style={{ color: "red" }}>{errors.companyHomepage}</span>
              </Grid>
              <Grid item xs={12} sm={6} className={classes.col}>
                <TextField
                  required
                  type="text"
                  label="E-Mail Firma"
                  value={companyEmail}
                  onChange={(e) => setCompanyEmail(e.target.value)}
                  fullWidth
                />
                <span style={{ color: "red" }}>{errors.companyEmail}</span>
              </Grid>
              <Grid
                item
                xs={12}
                className={classes.col}
                style={{ marginTop: 20 }}
              >
                <span style={{ fontSize: 18, color: "#000" }}>
                  Rahmenbedingungen Kunde
                </span>
              </Grid>

              <Grid item xs={12} sm={6} className={classes.col}>
                <InputLabel htmlFor="age-native-required">
                  Zahlungsbedingungen Firma
                </InputLabel>
                <Select
                  native
                  style={{ width: "100%" }}
                  value={companyPaymentCondition}
                  onChange={(e) => setCompanyPaymentCondition(e.target.value)}
                  name="companyPaymentCondition"
                  inputProps={{
                    id: "age-native-required",
                  }}
                >
                  <option value={"Bitte auswählen!"}>Bitte auswählen!</option>
                  <option value={"8 Tage netto"}>8 Tage netto</option>
                  <option value={"14 Tage netto"}>14 Tage netto</option>
                  <option value={"Barzahlung bei Erhalt der Ware"}>
                    Barzahlung bei Erhalt der Ware
                  </option>
                  <option value={"8 Tage 2% / 30 Tage netto"}>
                    8 Tage 2% / 30 Tage netto
                  </option>
                  <option value={"Sonstiges"}>Sonstiges</option>
                </Select>
                <span style={{ color: "red" }}>
                  {errors.companyPaymentCondition}
                </span>
              </Grid>

              <Grid item xs={12} sm={6} className={classes.col}>
                {(() => {
                  if (companyPaymentCondition === "Sonstiges") {
                    return (
                      <>
                        <TextField
                          required
                          type="text"
                          label="Sonstige Kondition Kunde"
                          value={companyCustomPaymentCondition}
                          onChange={(e) =>
                            setCompanyCustomPaymentCondition(e.target.value)
                          }
                          fullWidth
                        />
                        <span style={{ color: "red" }}>
                          {errors.companyCustomPaymentCondition}
                        </span>
                      </>
                    );
                  } else {
                    return null;
                  }
                })()}
              </Grid>
              <Grid item xs={12} sm={12} className={classes.col}>
                <FormLabel component="legend">
                  Wünscht der Kunde SEPA?
                </FormLabel>
                <RadioGroup
                  row
                  aria-label="position"
                  value={companyWishedSepa}
                  onChange={(e) => setCompanyWishedSepa(e.target.value)}
                >
                  <FormControlLabel
                    value="ja"
                    control={<Radio color="primary" />}
                    label="ja"
                    labelPlacement="start"
                  />
                  <FormControlLabel
                    value="nein"
                    control={<Radio color="primary" />}
                    label="nein"
                    labelPlacement="start"
                  />
                </RadioGroup>
                <span style={{ color: "red" }}>{errors.companyWishedSepa}</span>
              </Grid>

              {(() => {
                if (companyWishedSepa === "ja") {
                  return (
                    <>
                      <Grid item xs={12} sm={12} className={classes.col}>
                        <TextField
                          required
                          type="text"
                          label="Bank Kunde"
                          value={companyBankAccount}
                          onChange={(e) =>
                            setCompanyBankAccount(e.target.value)
                          }
                          fullWidth
                        />
                        <span style={{ color: "red" }}>
                          {errors.companyBankAccount}
                        </span>
                      </Grid>
                      <Grid item xs={12} sm={6} className={classes.col}>
                        <TextField
                          required
                          type="text"
                          label="BIC Kunde"
                          value={companyBankBIC}
                          onChange={(e) => setcompanyBankBIC(e.target.value)}
                          fullWidth
                        />
                        <span style={{ color: "red" }}>
                          {errors.companyBankBIC}
                        </span>
                      </Grid>
                      <Grid item xs={12} sm={6} className={classes.col}>
                        <TextField
                          required
                          type="text"
                          label="IBAN Kunde"
                          value={companyBankIBAN}
                          onChange={(e) => setCompanyBankIBAN(e.target.value)}
                          fullWidth
                        />
                        <span style={{ color: "red" }}>
                          {errors.companyBankIBAN}
                        </span>
                      </Grid>
                    </>
                  );
                } else {
                  return null;
                }
              })()}

              <Grid item xs={12} sm={12} className={classes.col}>
                <FormLabel component="legend">
                  Andere Lieferadresse gewünscht?
                </FormLabel>
                <RadioGroup
                  row
                  aria-label="position"
                  value={otherDeliveryAdress}
                  onChange={(e) => setOtherDeliveryAdress(e.target.value)}
                >
                  <FormControlLabel
                    value="ja"
                    control={<Radio color="primary" />}
                    label="ja"
                    labelPlacement="start"
                  />
                  <FormControlLabel
                    value="nein"
                    control={<Radio color="primary" />}
                    label="nein"
                    labelPlacement="start"
                  />
                </RadioGroup>
                <span style={{ color: "red" }}>
                  {errors.otherDeliveryAdress}
                </span>
              </Grid>
              {(() => {
                if (otherDeliveryAdress === "ja") {
                  return (
                    <>
                      <Grid item xs={12} sm={12} className={classes.col}>
                        <TextField
                          required
                          type="text"
                          label="Name von anderer Lieferanschrift"
                          value={otherDeliveryAdressName}
                          onChange={(e) =>
                            setOtherDeliveryAdressName(e.target.value)
                          }
                          fullWidth
                        />
                        <span style={{ color: "red" }}>
                          {errors.otherDeliveryAdressName}
                        </span>
                      </Grid>
                      <Grid item xs={12} sm={12} className={classes.col}>
                        <TextField
                          required
                          type="text"
                          label="Straße von anderer Lieferanschrift"
                          value={otherDeliveryAdressStreet}
                          onChange={(e) =>
                            setOtherDeliveryAdressStreet(e.target.value)
                          }
                          fullWidth
                        />
                        <span style={{ color: "red" }}>
                          {errors.otherDeliveryAdressStreet}
                        </span>
                      </Grid>
                      <Grid item xs={12} sm={6} className={classes.col}>
                        <TextField
                          required
                          type="text"
                          label="PLZ von anderer Lieferanschrift"
                          value={otherDeliveryAdressPostalcode}
                          onChange={(e) =>
                            setOtherDeliveryAdressPostalcode(e.target.value)
                          }
                          fullWidth
                        />
                        <span style={{ color: "red" }}>
                          {errors.otherDeliveryAdressPostalcode}
                        </span>
                      </Grid>
                      <Grid item xs={12} sm={6} className={classes.col}>
                        <TextField
                          required
                          type="text"
                          label="Stadt von anderer Lieferanschrift"
                          value={otherDeliveryAdressCity}
                          onChange={(e) =>
                            setOtherDeliveryAdressCity(e.target.value)
                          }
                          fullWidth
                        />
                        <span style={{ color: "red" }}>
                          {errors.otherDeliveryAdressCity}
                        </span>
                      </Grid>
                    </>
                  );
                } else {
                  return null;
                }
              })()}

              <Grid item xs={12} sm={6} className={classes.col}>
                <InputLabel htmlFor="age-native-required">
                  Geschlecht von Kontakt Kunde
                </InputLabel>
                <Select
                  native
                  style={{ width: "100%" }}
                  value={contactSex}
                  onChange={(e) => setContactSex(e.target.value)}
                  name="contactSex"
                  inputProps={{
                    id: "age-native-required",
                  }}
                >
                  <option value={"Herr"}>Herr</option>
                  <option value={"Frau"}>Frau</option>
                  <option value={"Divers"}>Divers</option>
                </Select>
                <span style={{ color: "red" }}>{errors.contactSex}</span>
              </Grid>
              <Grid item xs={12} sm={6} className={classes.col}>
                <TextField
                  required
                  type="text"
                  label="Nachname von Kontakt Kunde"
                  value={contactName}
                  onChange={(e) => setContactName(e.target.value)}
                  fullWidth
                />
                <span style={{ color: "red" }}>{errors.contactName}</span>
              </Grid>
              <Grid item xs={12} sm={6} className={classes.col}>
                <TextField
                  required
                  type="text"
                  label="Vorname von Kontakt Kunde"
                  value={contactPrename}
                  onChange={(e) => setContactPrename(e.target.value)}
                  fullWidth
                />
                <span style={{ color: "red" }}>{errors.contactPrename}</span>
              </Grid>
              <Grid item xs={12} sm={6} className={classes.col}>
                <TextField
                  required
                  type="text"
                  label="Telefon von Kontakt Kunde"
                  value={contactTelefon}
                  onChange={(e) => setContactTelefon(e.target.value)}
                  fullWidth
                />
                <span style={{ color: "red" }}>{errors.contactTelefon}</span>
              </Grid>
              <Grid item xs={12} sm={6} className={classes.col}>
                <TextField
                  type="text"
                  label="Fax von Kontakt Kunde"
                  value={contactFax}
                  onChange={(e) => setContactFax(e.target.value)}
                  fullWidth
                />
                <span style={{ color: "red" }}>{errors.contactFax}</span>
              </Grid>
              <Grid item xs={12} sm={6} className={classes.col}>
                <TextField
                  required
                  type="text"
                  label="E-Mail von Kontakt Kunde"
                  value={contactEmail}
                  onChange={(e) => setContactEmail(e.target.value)}
                  fullWidth
                />
                <span style={{ color: "red" }}>{errors.contactEmail}</span>
              </Grid>

              <Grid item xs={12} sm={6} className={classes.col}>
                <InputLabel htmlFor="age-native-required">
                  Abteilung von Kontakt Kunde
                </InputLabel>
                <Select
                  native
                  style={{ width: "100%" }}
                  value={contactDepartment}
                  onChange={(e) => setContactDepartment(e.target.value)}
                  name="Abteilung von Kontakt Kunde"
                  inputProps={{
                    id: "age-native-required",
                  }}
                >
                  <option value={"Allgemein"}>Allgemein</option>
                  <option value={"Arbeitsmedizin"}>Arbeitsmedizin</option>
                  <option value={"Arbeitssicherheit/FaSi"}>
                    Arbeitssicherheit/FaSi
                  </option>
                  <option value={"Arbeitsvorbereitung"}>
                    Arbeitsvorbereitung
                  </option>
                  <option value={"Bauhof"}>Bauhof</option>
                  <option value={"Beschläge"}>Beschläge</option>
                  <option value={"Betriebsrat"}>Betriebsrat</option>
                  <option value={"Betriebsrat Arbeitsschutz"}>
                    Betriebsrat Arbeitsschutz
                  </option>
                  <option value={"EDV/IT"}>EDV/IT</option>
                  <option value={"Einkauf"}>Einkauf</option>
                  <option value={"Einkauf Arbeitsschutz"}>
                    Einkauf Arbeitsschutz
                  </option>
                  <option value={"Elektro"}>Elektro</option>
                  <option value={"Feuerwehr/Brandschutz"}>
                    Feuerwehr/Brandschutz
                  </option>
                  <option value={"Finanzen"}>Finanzen</option>
                  <option value={"Forschung"}>Forschung</option>
                  <option value={"Hausmeister/Facilitymanagement"}>
                    Hausmeister/Facilitymanagement
                  </option>
                  <option value={"Instandhaltung"}>Instandhaltung</option>
                  <option value={"Lager"}>Lager</option>
                  <option value={"Logistik"}>Logistik</option>
                  <option value={"Magazin"}>Magazin</option>
                  <option value={"Marketing/CI"}>Marketing/CI</option>
                  <option value={"Pforte/Empfang"}>Pforte/Empfang</option>
                  <option value={"Pneumatik/Technik"}>Pneumatik/Technik</option>
                  <option value={"Produktion"}>Produktion</option>
                  <option value={"Qualität"}>Qualität</option>
                  <option value={"Sanitär/Heizung"}>Sanitär/Heizung</option>
                  <option value={"Schilder"}>Schilder</option>
                  <option value={"Service"}>Service</option>
                  <option value={"Technik"}>Technik</option>
                  <option value={"Umwelt"}>Umwelt</option>
                  <option value={"Verkauf"}>Verkauf</option>
                  <option value={"Verwaltung"}>Verwaltung</option>
                  <option value={"Werkstatt"}>Werkstatt</option>
                  <option value={"Werkschutz"}>Werkschutz</option>
                </Select>
                <span style={{ color: "red" }}>{errors.contactDepartment}</span>
              </Grid>
              <Grid item xs={12} sm={6} className={classes.col}>
                <InputLabel htmlFor="age-native-required">
                  Funktion von Kontakt Kunde
                </InputLabel>
                <Select
                  native
                  style={{ width: "100%" }}
                  value={contactFunction}
                  onChange={(e) => setContactFunction(e.target.value)}
                  name="Funktion von Kontakt Kunde"
                  inputProps={{
                    id: "age-native-required",
                  }}
                >
                  <option value={"Abteilungsleiter"}>Abteilungsleiter</option>
                  <option value={"Allgemein"}>Allgemein</option>
                  <option value={"Arbeitssicherheit/Arbeitsmedizin"}>
                    Arbeitssicherheit/Arbeitsmedizin
                  </option>
                  <option value={"Außendienst"}>Außendienst</option>
                  <option value={"Betriebs- und Lagertechnik"}>
                    Betriebs- und Lagertechnik
                  </option>
                  <option value={"Betriebsrat"}>Betriebsrat</option>
                  <option value={"Brandschutz"}>Brandschutz</option>
                  <option value={"Buchhaltung"}>Buchhaltung</option>
                  <option value={"Datenschutzbeauftragter"}>
                    Datenschutzbeauftragter
                  </option>
                  <option value={"Einkäufer"}>Einkäufer</option>
                  <option value={"Geschäftsführer"}>Geschäftsführer</option>
                  <option value={"Inhaber/-in"}>Inhaber/-in</option>
                  <option value={"Management"}>Management</option>
                  <option value={"Meister/-in"}>Meister/-in</option>
                  <option value={"Mitarbeiter/-Sachbearbeiter"}>
                    Mitarbeiter/-Sachbearbeiter
                  </option>
                  <option value={"Produktmanager"}>Produktmanager</option>
                  <option value={"Projektmanager"}>Projektmanager</option>
                  <option value={"Sicherheitsbeauftragter"}>
                    Sicherheitsbeauftragter
                  </option>
                  <option value={"Sicherheitsfachkraft"}>
                    Sicherheitsfachkraft
                  </option>
                  <option value={"Teamleiter"}>Teamleiter</option>
                  <option value={"Umweltschutz"}>Umweltschutz</option>
                  <option value={"Verwaltung"}>Verwaltung</option>
                  <option value={"Vorstand"}>Vorstand</option>
                  <option value={"Werkzeug"}>Werkzeug</option>
                </Select>
                <span style={{ color: "red" }}>{errors.contactFunction}</span>
              </Grid>
              <Grid item xs={12} sm={6} className={classes.col}>
                <InputLabel>Innendienstmitarbeiter 1</InputLabel>
                <Select
                  native
                  style={{ width: "100%" }}
                  value={customerIDM1}
                  onChange={(e) => setCustomerIDM1(e.target.value)}
                  name="Innendienstmitarbeiter 1"
                  inputProps={{
                    id: "age-native-required",
                  }}
                >
                  <option value="keine Angabe">keine Angabe</option>
                  {users.map((u, idx) => (
                    <option key={idx} value={u.short}>
                      {u.name}, {u.prename}
                    </option>
                  ))}
                </Select>
                <span style={{ color: "red" }}>{errors.customerIDM1}</span>
              </Grid>
              <Grid item xs={12} sm={6} className={classes.col}>
                <InputLabel htmlFor="age-native-required">
                  Innendienstmitarbeiter 2
                </InputLabel>
                <Select
                  native
                  style={{ width: "100%" }}
                  value={customerIDM2}
                  onChange={(e) => setCustomerIDM2(e.target.value)}
                  name="Innendienstmitarbeiter 2"
                  inputProps={{
                    id: "age-native-required",
                  }}
                >
                  <option value="keine Angabe">keine Angabe</option>
                  {users.map((u, idx) => (
                    <option key={idx} value={u.short}>
                      {u.name}, {u.prename}
                    </option>
                  ))}
                </Select>
                <span style={{ color: "red" }}>{errors.customerIDM2}</span>
              </Grid>
              <Grid item xs={12} sm={6} className={classes.col}>
                <TextField
                  required
                  disabled
                  type="text"
                  label="Außendienstmitarbeiter"
                  value={currentUser.name + ", " + currentUser.prename}
                  fullWidth
                />
                <span style={{ color: "red" }}>{errors.customerADM}</span>
              </Grid>

              <Grid item xs={12} sm={6} className={classes.col}>
                <TextField
                  type="text"
                  label="E-Mail Rechnungsversand"
                  value={invoiceEmail}
                  onChange={(e) => setInvoiceEmail(e.target.value)}
                  fullWidth
                />
                <span style={{ color: "red" }}>{errors.invoiceEmail}</span>
              </Grid>

              <Grid item xs={12} sm={12} className={classes.col}>
                <FormLabel component="legend">
                  Erhalt von Informationen genehmigen
                </FormLabel>
                <RadioGroup
                  row
                  aria-label="position"
                  value={companyWishedAds}
                  onChange={(e) => setCompanyWishedAdsConcl(e.target.value)}
                >
                  <FormControlLabel
                    value="ja"
                    control={<Radio color="primary" />}
                    label="ja"
                    labelPlacement="start"
                  />
                  <FormControlLabel
                    value="nein"
                    control={<Radio color="primary" />}
                    label="nein"
                    labelPlacement="start"
                  />
                </RadioGroup>
                <span style={{ color: "red" }}>{errors.companyWishedAds}</span>
              </Grid>
              {(() => {
                if (companyWishedAds === "ja") {
                  return (
                    <>
                      <Grid item xs={12} className={classes.col}>
                        <span style={{ fontSize: 18, color: "#000" }}>
                          Info per Email
                        </span>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <FormGroup>
                          <FormControlLabel
                            control={
                              <Checkbox
                                color="primary"
                                onChange={(e) =>
                                  addInterestToArrayEmail(e.target)
                                }
                                value="Angebote"
                              />
                            }
                            label="Angebote"
                          />
                          <FormControlLabel
                            control={
                              <Checkbox
                                color="primary"
                                onChange={(e) =>
                                  addInterestToArrayEmail(e.target)
                                }
                                value="Einladung A+A"
                              />
                            }
                            label="Einladung A+A"
                          />
                          <FormControlLabel
                            control={
                              <Checkbox
                                color="primary"
                                onChange={(e) =>
                                  addInterestToArrayEmail(e.target)
                                }
                                value="Einladung BBQ"
                              />
                            }
                            label="Einladung BBQ"
                          />
                          <FormControlLabel
                            control={
                              <Checkbox
                                color="primary"
                                onChange={(e) =>
                                  addInterestToArrayEmail(e.target)
                                }
                                value="Newsletter"
                              />
                            }
                            label="Newsletter"
                          />
                        </FormGroup>
                        <span style={{ color: "red" }}>{errors.infoEmail}</span>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        className={classes.col}
                        style={{ marginTop: 20 }}
                      >
                        <span style={{ fontSize: 18, color: "#000" }}>
                          Welche Kataloge?
                        </span>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <FormGroup>
                          <FormControlLabel
                            control={
                              <Checkbox
                                color="primary"
                                onChange={(e) =>
                                  addInterestToArrayWare(e.target)
                                }
                                value="Betriebs- und Lagertechnik / Basic"
                              />
                            }
                            label="Betriebs- und Lagertechnik / Basic"
                          />
                          <FormControlLabel
                            control={
                              <Checkbox
                                color="primary"
                                onChange={(e) =>
                                  addInterestToArrayWare(e.target)
                                }
                                value="Schilder"
                              />
                            }
                            label="Schilder"
                          />
                          <FormControlLabel
                            control={
                              <Checkbox
                                color="primary"
                                onChange={(e) =>
                                  addInterestToArrayWare(e.target)
                                }
                                value="Stürmer/Elmag"
                              />
                            }
                            label="Stürmer/Elmag"
                          />
                          <FormControlLabel
                            control={
                              <Checkbox
                                color="primary"
                                onChange={(e) =>
                                  addInterestToArrayWare(e.target)
                                }
                                value="Elektrowerkzeuge"
                              />
                            }
                            label="Elektrowerkzeuge"
                          />
                          <FormControlLabel
                            control={
                              <Checkbox
                                color="primary"
                                onChange={(e) =>
                                  addInterestToArrayWare(e.target)
                                }
                                value="Werkzeug"
                              />
                            }
                            label="Werkzeug"
                          />
                          <FormControlLabel
                            control={
                              <Checkbox
                                color="primary"
                                onChange={(e) =>
                                  addInterestToArrayWare(e.target)
                                }
                                value="Sanitär/Heizung"
                              />
                            }
                            label="Sanitär/Heizung"
                          />
                          <FormControlLabel
                            control={
                              <Checkbox
                                color="primary"
                                onChange={(e) =>
                                  addInterestToArrayWare(e.target)
                                }
                                value="Befestigungstechnik"
                              />
                            }
                            label="Befestigungstechnik"
                          />
                          <FormControlLabel
                            control={
                              <Checkbox
                                color="primary"
                                onChange={(e) =>
                                  addInterestToArrayWare(e.target)
                                }
                                value="Hebetechnik"
                              />
                            }
                            label="Hebetechnik"
                          />
                          <FormControlLabel
                            control={
                              <Checkbox
                                color="primary"
                                onChange={(e) =>
                                  addInterestToArrayWare(e.target)
                                }
                                value="Büroartikel"
                              />
                            }
                            label="Büroartikel"
                          />
                          <FormControlLabel
                            control={
                              <Checkbox
                                color="primary"
                                onChange={(e) =>
                                  addInterestToArrayWare(e.target)
                                }
                                value="Beschläge/Holzfensterbau"
                              />
                            }
                            label="Beschläge/Holzfensterbau"
                          />
                          <FormControlLabel
                            control={
                              <Checkbox
                                color="primary"
                                onChange={(e) =>
                                  addInterestToArrayWare(e.target)
                                }
                                value="Metallverarbeitung"
                              />
                            }
                            label="Metallverarbeitung"
                          />
                          <FormControlLabel
                            control={
                              <Checkbox
                                color="primary"
                                onChange={(e) =>
                                  addInterestToArrayWare(e.target)
                                }
                                value="NW Beschlagskatalog"
                              />
                            }
                            label="NW Beschlagskatalog"
                          />
                          <FormControlLabel
                            control={
                              <Checkbox
                                color="primary"
                                onChange={(e) =>
                                  addInterestToArrayWare(e.target)
                                }
                                value="Arbeitsschutz"
                              />
                            }
                            label="Arbeitsschutz"
                          />
                          <FormControlLabel
                            control={
                              <Checkbox
                                color="primary"
                                onChange={(e) =>
                                  addInterestToArrayWare(e.target)
                                }
                                value="Gefahrenstofflagerungen"
                              />
                            }
                            label="Gefahrenstofflagerungen"
                          />
                          <FormControlLabel
                            control={
                              <Checkbox
                                color="primary"
                                onChange={(e) =>
                                  addInterestToArrayWare(e.target)
                                }
                                value="Aktionsprospekte"
                              />
                            }
                            label="Aktionsprospekte"
                          />
                          <FormControlLabel
                            control={
                              <Checkbox
                                color="primary"
                                onChange={(e) =>
                                  addInterestToArrayWare(e.target)
                                }
                                value="NW Sanitärkatalog"
                              />
                            }
                            label="NW Sanitärkatalog"
                          />
                        </FormGroup>
                        <span style={{ color: "red" }}>
                          {errors.interestWare}
                        </span>
                      </Grid>
                    </>
                  );
                } else {
                  return null;
                }
              })()}
              <Grid
                item
                xs={12}
                className={classes.col}
                style={{ textAlign: "center", marginTop: 20 }}
              >
                {!loadingSendingForm ? (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => validateForm()}
                  >
                    Kunde anlegen
                  </Button>
                ) : (
                  <Button
                    disabled
                    variant="contained"
                    color="primary"
                    onClick={() => console.log("test")}
                  >
                    sendet...
                  </Button>
                )}
              </Grid>
            </Grid>
            <Grid item xs={1}></Grid>
          </Grid>
        </>
      )}
    </>
  );
}
