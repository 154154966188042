import { useEffect, useState } from "react";
import { Router } from "@reach/router";
import CircularProgress from "@material-ui/core/CircularProgress";
import CookieService from "../services/CookieService";
import TabBar from "../components/TabBar.js";
import ProtectedRoutes from "./protected.routes";
import LoginScreen from "../components/LoginScreen";


export default function Routes() {
  const [authenticated, setAuthenticated] = useState(false);
  const [loadingAuth, setLoadingAuth] = useState(true);

  useEffect(() => {
    const accessToken = CookieService.get("access_token");
    if (accessToken) {
      setAuthenticated(true);
    }
    setLoadingAuth(false);
  }, []);

  return (
    <>
      {loadingAuth ? (
        <div style={{ textAlign: "center", marginTop: "45vh" }}>
          <CircularProgress style={{ color: "#00699a" }} />
        </div>
      ) : (
        <>
          {authenticated ? <ProtectedRoutes /> : <LoginScreen />}
          {authenticated ? (
            <Router>
              <TabBar path="/*" />
            </Router>
          ) : null}
        </>
      )}
    </>
  );
}
