import { Router } from "@reach/router";

import Neukunden from "../components/Neukunden";
import NeukundenForm from "../components/neukunden/NeukundenForm";
import Retouren from "../components/Retouren";
import Repairs from "../components/Repairs";
import Profil from "../components/Profil";

const ProtectedRoutes = () => (
  <Router>
    <Neukunden path="/" />
    <NeukundenForm path="neukunde/:type" />
    <Retouren path="returns" />
    <Profil path="profile" />
    <Repairs path="repairs" />
  </Router>
);

export default ProtectedRoutes;
